import {useQuery} from '@tanstack/react-query';
import {fetchApi} from '../../utils/fetchApi';
import {useMemo} from 'react';

export interface Invoice {
  number: string;
  created: number;
  total: number;
  currency: string;
  invoice_pdf: string;
  customer_address: {country: string};
}

export const useInvoices = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['invoices'],
    queryFn: () => fetchApi('/subscription/invoices'),
  });
  const invoices = useMemo(() => data || [], [data]);

  return {
    invoices,
    loading: isLoading,
  };
};
