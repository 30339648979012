import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {LinkedInHeader} from './components/linkedInHeader';
import {FAQ} from './components/FAQ';
import {Footer} from './components/Footer';
import {Credits} from './components/Credits';
import CopyIcon from '../../components/atoms/Icons/CopyIcon';
import {LightTab} from '../../components/atoms/LightTab';
import {MobileIcon} from '../../components/atoms/Icons/MobileIcon';
import {DesktopIcon} from '../../components/atoms/Icons/DesktopIcon';
import {Button} from '../../components/atoms/Button';
import {useSnackbar} from 'notistack';
import {isTablet} from '../../utils/responsive';
import {HeaderNavbar} from './components/HeaderNavbar';
import {DiscoverSection} from './components/DiscoverSection';
import {HowToUseTextFormatter} from './components/howToUse/HowToUseTextFormatter';
import {Editor} from './components/Editor';

const faqData = [
  {
    question: 'Is this tool free?',
    answer:
      'Yes, our LinkedIn Text Formatter is completely free to use. No hidden fees or surprises!',
  },
  {
    question:
      'Is there a limit to how much I can use the RedactAI LinkedIn Formatter?',
    answer:
      'No, there are no limits! You can format as many LinkedIn posts as you want with our tool.',
  },
  {
    question: 'Does RedactAI offer other free tools?',
    answer:
      'Absolutely! RedactAI provides a variety of free tools designed to enhance your LinkedIn experience. Use our tools to boost your posts, generate effective profile headlines, and more, all aimed at expanding your professional network.',
  },
  {
    question: 'Is formatting my post enough to increase LinkedIn engagement?',
    answer: `While formatting can make your posts more visually appealing, it alone doesn't guarantee higher engagement. For the best results, focus on crafting posts that include:

- An engaging headline and opening sentence
- Clear and concise messaging
- Content that is relevant and valuable to your target audience
- Effective use of formatting
- A call to action that encourages engagement or invites discussion
- Proofreading for grammar and spelling accuracy
- Consistent posting frequency and style

RedactAI’s AI-powered content creation tools can simplify your LinkedIn strategy. Use them to write compelling posts, blog articles, or YouTube video scripts, create eye-catching carousels, or discover trending topics in your industry.

With RedactAI’s LinkedIn scheduling and analytics, you can maintain a consistent presence and identify the best times to post, maximizing your reach and engagement rates.`,
  },
];

export const TextFormatter: FunctionComponent = () => {
  const {enqueueSnackbar} = useSnackbar();
  const [postText, setPostText] = useState<string>('');
  const [showFullText, setShowFullText] = useState(false);
  const [isLaptopPreview, setIsLaptopPreview] = useState<boolean>(true);
  const toggleShowFullText = () => setShowFullText(!showFullText);
  const handleCopy = async () => {
    await navigator.clipboard.writeText(postText);
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
    });
  };
  return (
    <>
      <HeaderNavbar />
      <Parent>
        <MainContainer>
          <Header>
            <LinkedInHeader />
            <Span>Text</Span>
            <Span> Formatter</Span>
          </Header>
          <SubHeader>
            Customize your LinkedIn post with bold, italic, strikethrough, and
            other text styles!
          </SubHeader>
          <Credits />
          <TextFormatterContainer>
            <LeftContainer>
              <Editor setPostText={setPostText} postText={postText} />
            </LeftContainer>
            <RightContainer>
              <Row>
                <LightTab
                  isSelected={isLaptopPreview}
                  label="Web"
                  icon={<DesktopIcon />}
                  onClick={() => setIsLaptopPreview(true)}
                />
                <LightTab
                  isSelected={!isLaptopPreview}
                  label="Mobile"
                  icon={<MobileIcon />}
                  onClick={() => setIsLaptopPreview(false)}
                />
              </Row>
              <PreviewContainer $isLaptopPreview={isLaptopPreview}>
                <AvatarSection>
                  <AvatarWrapper>
                    <Avatar>AD</Avatar>
                  </AvatarWrapper>
                  <UserInfo>
                    <UserName>Abderrahim Defaoui</UserName>
                    <UserSubtitle>
                      I use RedactAI because I'm smart
                    </UserSubtitle>
                    <TimeAgo>
                      an hour
                      <Dot />
                    </TimeAgo>
                  </UserInfo>
                </AvatarSection>
                <Text>
                  {!showFullText ? (
                    <PostText $isLaptopPreview={isLaptopPreview}>
                      {postText}
                      <SeeMoreText onClick={toggleShowFullText}>
                        ...see more
                      </SeeMoreText>
                    </PostText>
                  ) : (
                    <div>
                      {postText}
                      <SeeLessText onClick={toggleShowFullText}>
                        See less
                      </SeeLessText>
                    </div>
                  )}
                </Text>
              </PreviewContainer>
              <ButtonWrapper>
                <div />
                <StyledButton
                  onClick={handleCopy}
                  variant="light"
                  size="small"
                  startIcon={<CopyIcon />}
                  label="Copy"
                />
              </ButtonWrapper>
            </RightContainer>
          </TextFormatterContainer>
        </MainContainer>
        <HowToUseTextFormatter />
        <DiscoverSection />
        <FAQ faqs={faqData} />
      </Parent>
      <Footer />
    </>
  );
};

const Parent = styled.div`
  padding: 0 1.5em;
  display: flex;
  flex-direction: column;
  gap: 3em;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  min-height: calc(100vh - 5.5em);
  @media (min-width: ${isTablet}) {
    width: 60em;
  }
`;
const Header = styled.div`
  font-weight: 700;
  font-size: 3.7em;
  color: ${({theme}) => theme.colors.primary4.shade2};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 0.2em;
`;
const Span = styled.span`
  text-align: center;
`;
const SubHeader = styled.span`
  font-weight: 600;
  font-size: 1.5em;
  color: ${({theme}) => theme.colors.neutral.shade9};
  text-align: center;
  margin-top: 2em;
`;
const Text = styled.span`
  line-height: 1.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  letter-spacing: 0.03125em;
  white-space: pre-wrap;
  word-wrap: break-word;
`;
const TextFormatterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 2em auto 0 auto;
  overflow: hidden;
  border: 1px solid ${({theme}) => theme.colors.neutral.shade6};
  border-radius: 1.5em;
  width: 100%;
  @media (min-width: ${isTablet}) {
    height: 35em;
  }
  @media (max-width: ${isTablet}) {
    flex-direction: column;
  }
`;
const RightContainer = styled.div`
  padding: 0.75em;
  background-color: ${({theme}) => theme.colors.neutral.shade13};
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  width: 50%;
  @media (max-width: ${isTablet}) {
    width: 100%;
    box-sizing: border-box;
    height: 24em;
    border-top: 1px solid ${({theme}) => theme.colors.neutral.shade6};
  }
  @media (min-width: ${isTablet}) {
    border-left: 1px solid ${({theme}) => theme.colors.neutral.shade6};
  }
`;
const PreviewContainer = styled.div<{$isLaptopPreview: boolean}>`
  flex: 1;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  padding: 1.5em;
  border-radius: 1em;
  overflow-y: scroll;
  box-sizing: border-box;
  width: ${({$isLaptopPreview}) => ($isLaptopPreview ? '100%' : '80%')};
  margin: 0 auto;
  &::-webkit-scrollbar {
    display: none;
  }
  white-space: pre-wrap;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  transition: all 0.3s;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledButton = styled(Button)`
  width: fit-content;
`;
const AvatarWrapper = styled.div`
  height: 3.6em;
  width: 3.6em;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.neutral.shade5};
  margin-top: 0.15em;
`;
const Avatar = styled.p`
  font-size: 1.25em;
  font-weight: 700;
`;
const UserInfo = styled.div`
  color: #000000;
  font-size: 1em;
`;
const UserName = styled.p`
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.0125em;
  margin: 0;
`;
const UserSubtitle = styled.p`
  font-size: 0.8125em;
  letter-spacing: 0.00625em;
  opacity: 60%;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
`;
const TimeAgo = styled.span`
  font-size: 0.8em;
  opacity: 60%;
  display: flex;
  align-items: center;
`;
const Dot = styled.span`
  width: 0.3em;
  height: 0.3em;
  background-color: ${({theme}) => theme.colors.neutral.shade11};
  border-radius: 50%;
  margin-left: 0.5em;
`;
const AvatarSection = styled.div`
  display: flex;
  gap: 0.9375em;
`;
const PostText = styled.div<{$isLaptopPreview: boolean}>`
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: ${({$isLaptopPreview}) => ($isLaptopPreview ? 4 : 2)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const SeeLessText = styled.span`
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: block;
  margin-top: 0.5em;
`;

const SeeMoreText = styled.span`
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  padding-left: 10px;
  cursor: pointer;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
`;

const LeftContainer = styled.div`
  width: 50%;
  @media (max-width: ${isTablet}) {
    width: 100%;
    box-sizing: border-box;
    height: 18em;
  }
`;
