import {FunctionComponent} from 'react';
import styled from 'styled-components';
import RedactAILogo from '../../../components/atoms/Images/RedactAILogo';

export const Credits: FunctionComponent = () => {
  return (
    <CreditsContainer>
      <Span>Powered by</Span>
      <StyledRedactAILogo
        onClick={() => window.open('https://www.redactai.io/')}
        width="100"
      />
    </CreditsContainer>
  );
};

const CreditsContainer = styled.span`
  font-weight: 600;
  font-size: 1.4em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  text-align: center;
  margin-top: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
`;

const Span = styled.span`
  text-align: center;
`;
const StyledRedactAILogo = styled(RedactAILogo)`
  cursor: pointer;
`;
