import {FunctionComponent} from 'react';
import styled from 'styled-components';

export const HowToUseTextFormatter: FunctionComponent = () => {
  return (
    <TutoContainer>
      <TutoList>
        <Div>
          <Title>How to Use RedactAI to Format LinkedIn Posts</Title>
          <Text>
            {`1. Start by composing your LinkedIn post. If you need inspiration, try RedactAI’s viral post generator.
2. Highlight and copy the specific text that you want to format.
3. Paste the copied text into RedactAI’s LinkedIn text formatter.`}
          </Text>
          <div>
            <StyledImage
              src="https://i.ibb.co/PQHrKGL/Screenshot-2024-09-13-at-09-00-50.png"
              alt="Write your LinkedIn post"
            />
          </div>
          <Text>
            {`4. Choose your preferred text style, including font, size, and effects. Mix and match to create the look you want.`}
          </Text>
          <div>
            <StyledImage
              src="https://i.ibb.co/c3XTc1M/Screenshot-2024-09-13-at-09-02-16.png"
              alt="Select how you want your text to look"
            />
          </div>
          <Text>
            {`5. Once you’re happy with the formatting, click the 'Copy' button and paste the text into your LinkedIn post or your scheduling tool.`}
          </Text>
        </Div>
        <Div>
          <Title>Why Format Your LinkedIn Posts?</Title>
          <Text>
            {`Formatting your LinkedIn posts can significantly enhance your engagement. Here’s why:
- Increased visual appeal: Well-formatted posts are more eye-catching, helping your content stand out in a busy feed.
- Emphasize key points: Use bold, italics, or underlining to highlight important messages, making it easier for your audience to grasp your main ideas.
- Improved readability: Properly formatted text improves readability, allowing readers to quickly scan and understand your message.
- Consistent branding: Applying a consistent style to your posts helps build your personal or brand identity, making your content more recognizable.`}
          </Text>
        </Div>
        <Div>
          <Title>Avoid Over-Formatting Your LinkedIn Posts</Title>
          <div>
            <StyledImage
              src="https://i.ibb.co/fvWgT1D/Screenshot-2024-09-13-at-09-03-10.png"
              alt="Don't Over-format"
            />
          </div>
          <Text>
            {`While formatting can enhance your posts, it’s important not to overdo it. Here’s why:
- Searchability: Over-formatting can make your text less searchable on LinkedIn. Avoid formatting essential information like job titles or skills if you want them to be easily found.
- Accessibility concerns: Excessive formatting may create challenges for users with visual impairments or those using screen readers.
- Display issues: Not all devices or browsers may support certain formats, which could cause your text to appear incorrectly, potentially confusing your audience.`}
          </Text>
        </Div>
      </TutoList>
    </TutoContainer>
  );
};

const TutoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60em;
  margin: 0 auto;
`;

const TutoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Title = styled.span`
  font-size: 1.35em;
  font-weight: 700;
`;
const Text = styled.span`
  font-size: 1.15em;
  line-height: 1.5em;
  white-space: pre-wrap;
  word-wrap: break-word;
`;
const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;
