import {FunctionComponent, useEffect} from 'react';
import {Page} from '../../components/templates/Page';
import {PricingTable} from '../../components/organisms/PricingTable';
import {useTracking} from '../../hooks/useTracking';

export const Pricing: FunctionComponent = () => {
  const {trackEvent} = useTracking();
  useEffect(() => {
    trackEvent('Pricing - Open page');
  }, [trackEvent]);
  return (
    <Page>
      <PricingTable />
    </Page>
  );
};
