import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';
import {fetchApi} from '../../utils/fetchApi';

export enum ProductPeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export interface Subscription {
  id: string;
  remainingCredits: number;
  paymentRenewalDate: Date;
  creditsRenewalDate: Date;
  createdAt: Date;
  isActive: boolean;
  isInTrial: boolean;
  product: {
    credits: number;
    name: string;
    price: number;
    period: ProductPeriod;
    posts: number;
  };
  cancelsAtPeriodEnd: boolean;
  validUntil: Date | null;
}

export const useSubscription = () => {
  const {data, isLoading, refetch} = useQuery({
    queryKey: ['subscription'],
    queryFn: () => fetchApi('/subscription'),
  });

  const subscription: Subscription | undefined = useMemo(
    () =>
      !data
        ? undefined
        : {
            ...data,
            paymentRenewalDate: new Date(data?.paymentRenewalDate),
            creditsRenewalDate: new Date(data?.creditsRenewalDate),
          },
    [data],
  );

  return {
    subscription,
    loading: isLoading,
    refetch,
  };
};
