import {FunctionComponent} from 'react';
import styled from 'styled-components';
import VideoDownloaderScreenshot1 from '../../images/HowTo_VideoDownloader_1.png';
import VideoDownloaderScreenshot2 from '../../images/HowTo_VideoDownloader_2.png';

export const HowToUseVideoDownloader: FunctionComponent = () => {
  return (
    <TutoContainer>
      <TutoList>
        <Div>
          <Title>How to Download Videos from LinkedIn Using RedactAI</Title>
          <StyledList>
            <ListItem>
              <StepTitle>1. Get the Video Link</StepTitle>
              <Text>
                Find the LinkedIn post with the video you want to download.
                Click on the three dots in the upper right corner of the post,
                then select the "Copy link to post" option to get the video URL.
              </Text>
            </ListItem>
            <ListItem>
              <StepTitle>2. Enter the Video Link</StepTitle>
              <Text>
                Navigate to the RedactAI LinkedIn Video Downloader page and
                paste the copied video URL into the input box.
              </Text>
              <StyledImage
                src={VideoDownloaderScreenshot1}
                alt="Enter the Video Link"
              />
            </ListItem>
            <ListItem>
              <StepTitle>3. Initiate the Download</StepTitle>
              <Text>
                Click the "Download Video" button to start the download process.
                There are no restrictions on the number of videos you can
                download, and the service is completely free.
              </Text>
            </ListItem>
            <ListItem>
              <StepTitle>4. Preview and Save the Video</StepTitle>
              <Text>
                A preview of the video will appear, allowing you to watch it
                before saving. Once you're ready, download the video to your
                device or share it directly on LinkedIn.
              </Text>
              <StyledImage
                src={VideoDownloaderScreenshot2}
                alt="Preview and Save the Video"
              />
            </ListItem>
          </StyledList>
        </Div>
      </TutoList>
    </TutoContainer>
  );
};

const TutoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60em;
  margin: 0 auto;
  margin-top: 3em;
`;

const TutoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  justify-content: center;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 1.35em;
  font-weight: 700;
`;

const StepTitle = styled.h3`
  font-size: 1.2em;
  font-weight: 600;
  margin-top: 0.5em;
`;

const Text = styled.p`
  font-size: 1.15em;
  line-height: 1.5em;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: center;
`;

const StyledImage = styled.img`
  height: 60%;
  width: 60%;
  object-fit: contain;
  align-self: center;
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  text-align: center;
  margin: 0.5em 0;
`;

const ListItem = styled.li`
  font-size: 1.1em;
  line-height: 1.4em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
