import {FunctionComponent} from 'react';
import styled from 'styled-components';
import RedactAILogo from '../../../components/atoms/Images/RedactAILogo';
import {isSmallScreen, isTablet} from '../../../utils/responsive';
import {Button} from '../../../components/atoms/Button';
import {Link} from 'react-router-dom';
import {blogs, tools} from '../utils/FooterLinks';

export const Footer: FunctionComponent = () => {
  return (
    <FooterContainer>
      <LogoSection>
        <StyledRedactAILogo
          onClick={() => window.open('https://www.redactai.io/')}
        />
        <UnderLogoText>
          Enhance your LinkedIn profile to build your personal brand and attract
          new opportunities.
        </UnderLogoText>
        <Button
          onClick={() => window.open('https://www.redactai.io/')}
          size="medium"
          variant="main"
          label="Discover Redact AI"
        />
        <CopyWriteText>© 2024 Redact AI. All rights reserved.</CopyWriteText>
      </LogoSection>
      <ToolsSection>
        <SectionHeader>Explore our free LinkedIn tools</SectionHeader>
        <div>
          {tools.map((tool, index) => {
            return (
              <Item key={index} to={tool.link}>
                <ItemName>{tool.name}</ItemName>
                <ItemDescription>{tool.description}</ItemDescription>
              </Item>
            );
          })}
        </div>
      </ToolsSection>
      <BlogSection>
        <SectionHeader>Check out our free LinkedIn resources</SectionHeader>
        <div>
          {blogs.map((blog, index) => {
            return (
              <Item key={index} to={blog.link}>
                <ItemName>{blog.name}</ItemName>
                <ItemDescription>{blog.description}</ItemDescription>
              </Item>
            );
          })}
        </div>
      </BlogSection>
      <LogoSectionMobile>
        <StyledRedactAILogo
          onClick={() => window.open('https://www.redactai.io/')}
        />
        <UnderLogoText>
          Enhance your LinkedIn profile to build your personal brand and attract
          new opportunities.
        </UnderLogoText>
        <Button
          onClick={() => window.open('https://www.redactai.io/')}
          size="medium"
          variant="main"
          label="Discover Redact AI"
        />
        <CopyWriteText></CopyWriteText>
      </LogoSectionMobile>
    </FooterContainer>
  );
};
const FooterContainer = styled.footer`
  background-color: ${({theme}) => theme.colors.neutral.shade2};
  padding: 5em 10em;
  margin-top: 3em;
  border-top: 1px solid ${({theme}) => theme.colors.neutral.shade7};
  display: flex;
  gap: 1.5em;
  @media (max-width: ${isSmallScreen}) {
    padding: 5em;
  }
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    padding: 3em 2em;
  }
`;
const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 3em;
  flex: 1;
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
const LogoSectionMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin-top: 2em;
  flex: 1;
  @media (min-width: ${isTablet}) {
    display: none;
  }
`;
const ToolsSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75em;
`;
const BlogSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75em;
`;
const UnderLogoText = styled.span`
  font-weight: 600;
  font-size: 1.15em;
  line-height: 1.5em;
`;
const CopyWriteText = styled.span`
  font-size: 0.8em;
`;
const SectionHeader = styled.span`
  font-size: 1.5em;
  font-weight: 600;
  padding-left: 0.25em;
`;
const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 0.75em;
  padding: 0.5em;
  text-decoration: none;
  color: ${({theme}) => theme.colors.neutral.shade11};
  &:hover {
    background-color: ${({theme}) => theme.colors.primary1.shade8};
  }
`;
const ItemName = styled.span`
  font-weight: 600;
  font-size: 1em;
  line-height: 1.33em;
`;
const ItemDescription = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade10};
  line-height: 1.33em;
  font-size: 0.85em;
`;
const StyledRedactAILogo = styled(RedactAILogo)`
  cursor: pointer;
`;
