import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {Page} from '../../components/templates/Page';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import ArrowRight from '../../components/atoms/Icons/ArrowRightIcon';
import {useTheme} from '../../components/theme/theme';
import {LightTab} from '../../components/atoms/LightTab';
import {IdeaIcon} from '../../components/atoms/Icons/IdeaIcon';
import HistoryIcon from '../../components/atoms/Icons/HistoryIcon';
import {isTablet} from '../../utils/responsive';
import {TextInput} from '../../components/atoms/TextInput';
import HelpRoundedIcon from '../../components/atoms/Icons/HelpRoundedIcon';
import {LanguageSelect} from '../../components/atoms/LanguageSelect';
import useDefaultLanguage from '../../hooks/postLang/useDefaultLanguage';
import {Button} from '../../components/atoms/Button';
import {useFindIdeas} from '../../hooks/findIdeas/useFindIdeas';
import {GeneratedIdea} from '../../components/molecules/GeneratedIdea';
import {useSavedIdeas} from '../../hooks/savedPostsAndIdeas/useSavedIdeas';
import {MyIdeasTab} from './components/MyIdeasTab';
import {useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {NoIdeasToolTip} from '../onboarding/components/NoIdeasToolTip';

export const FindIdea: FunctionComponent = () => {
  const theme = useTheme();
  const findIdeas = useFindIdeas(() => {});
  const {savedIdeas: ideas} = useSavedIdeas();
  const {enqueueSnackbar} = useSnackbar();
  const [isHistoryTabActive, setIsHistoryTabActive] = useState<boolean>(false);
  const [postLang, setPostLang] = useDefaultLanguage();
  const [postTopic, setPostTopic] = useState<string>('');
  const [postTopicError, setPostTopicError] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isToolTip, setIsToolTip] = useState<boolean>(false);
  const [generatedIdeas, setGeneratedIdeas] = useState<
    {
      subject: string;
      ideas: string[];
    }[]
  >([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!findIdeas.isError) {
      return;
    }
    const error = findIdeas.error as Error;
    enqueueSnackbar(error.message, {
      variant: 'error',
    });
  }, [findIdeas.error, findIdeas.isError, enqueueSnackbar]);

  const onGenerateIdea = useCallback(() => {
    setIsSubmitted(true);
    if (!postTopic) {
      setPostTopicError(t`Please enter a post subject`);
      return;
    }
    setPostTopicError('');
    setIsSubmitted(false);
    findIdeas.mutate({
      subject: postTopic,
      language: postLang,
    });
  }, [findIdeas, postLang, postTopic]);

  useEffect(() => {
    void (async () => {
      if (findIdeas.isError || !findIdeas.isSuccess || !findIdeas.data) {
        return;
      }
      const data = await findIdeas.data.json();
      setGeneratedIdeas(data);
    })();
  }, [findIdeas.data, findIdeas.isError, findIdeas.isSuccess]);

  useEffect(() => {
    if (ideas.length === 0) {
      return;
    }

    const lastLanguageUsed = ideas.find((post) => !!post.language)?.language;
    if (lastLanguageUsed) {
      setPostLang(lastLanguageUsed);
    }
  }, [ideas, setPostLang]);
  return (
    <Page>
      <Container>
        <Header>
          <LeftText>{t`Find an idea`}</LeftText>
          <RightText>
            {t`Already have an idea ?`}
            <BlueText onClick={() => navigate('/redactPost')}>
              {t`Generate a post`}{' '}
              <ArrowRight
                color={theme.colors.primary4.shade1}
                width={14}
                height={14}
              />
            </BlueText>
          </RightText>
        </Header>
        {isHistoryTabActive ? (
          <MyIdeasTab {...{isHistoryTabActive, setIsHistoryTabActive, ideas}} />
        ) : (
          <>
            <ContentContainer>
              <Row>
                <LightTab
                  isSelected={!isHistoryTabActive}
                  label={t`Find an idea`}
                  icon={<IdeaIcon />}
                  onClick={() => setIsHistoryTabActive(false)}
                />
                <LightTab
                  isSelected={isHistoryTabActive}
                  label={t`History`}
                  icon={<HistoryIcon />}
                  onClick={() => setIsHistoryTabActive(true)}
                />
              </Row>
              <TopicAndLangContainer
                $postTopic={postTopic}
                $postTopicError={postTopicError}>
                <TextInputContainer>
                  <StyledTextInput
                    label={t`Topic or theme`}
                    placeholderText={t`Topic or theme you want to talk about. Example: "Digital Marketing" or "SEO".`}
                    value={postTopic}
                    onChange={(event) => setPostTopic(event.target.value)}
                    error={!postTopic && isSubmitted}
                    helperText={!postTopic && postTopicError}
                  />
                  <StyledHelpRoundedIcon
                    onClick={() => setIsToolTip(!isToolTip)}>
                    <StyHelpRoundedIcon />
                  </StyledHelpRoundedIcon>
                  {isToolTip && (
                    <NoIdeasToolTip
                      setIsToolTip={setIsToolTip}
                      isFindIdeaPage
                    />
                  )}
                </TextInputContainer>
                <StyledLanguageSelect {...{postLang, setPostLang}} />
              </TopicAndLangContainer>
            </ContentContainer>
            <Buttons>
              <StyledLightButton
                onClick={() => {
                  setPostTopic('');
                  setPostTopicError('');
                  setIsSubmitted(false);
                  setGeneratedIdeas([]);
                }}
                label={t`Reset`}
                size="medium"
                variant="light"
                disabled={findIdeas.isPending}
              />
              <StyledMainButton
                onClick={onGenerateIdea}
                label={t`Find my ideas`}
                size="medium"
                variant="main"
                disabled={findIdeas.isPending}
              />
            </Buttons>
            <IdeasContainer>
              {findIdeas.isPending ? (
                <>
                  <GeneratedIdea fullWidth isLoading />
                  <GeneratedIdea fullWidth isLoading />
                  <GeneratedIdea fullWidth isLoading />
                </>
              ) : (
                generatedIdeas.map((idea, index) => {
                  return (
                    <GeneratedIdea
                      fullWidth
                      key={index}
                      idea={idea}
                      onClick={() => {
                        navigate(
                          `/redactPost?subject=${
                            idea.subject
                          }&ideas=${idea.ideas.join('::')} `,
                        );
                      }}
                    />
                  );
                })
              )}
            </IdeasContainer>
          </>
        )}
      </Container>
    </Page>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({theme}) => theme.colors.neutral.shade11};
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    align-items: start;
    gap: 1em;
  }
`;
const LeftText = styled.span`
  font-weight: 700;
  font-size: 1.5em;
`;
const RightText = styled.span`
  font-weight: 600;
  font-size: 0.875em;
  letter-spacing: 0.0125em;
  display: flex;
  align-items: center;
  gap: 0.25em;
`;
const BlueText = styled.div`
  color: ${({theme}) => theme.colors.primary4.shade1};
  display: flex;
  align-items: center;
  gap: 0.25em;
  cursor: pointer;
`;
const ContentContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25em;
  justify-content: end;
`;
const TopicAndLangContainer = styled.div<{
  $postTopicError: string;
  $postTopic: string;
}>`
  display: flex;
  gap: 1em;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    gap: ${({$postTopicError, $postTopic}) =>
      $postTopicError && !$postTopic ? '2em' : '1.5em'};
  }
  @media (min-width: ${isTablet}) {
    margin-bottom: ${({$postTopicError, $postTopic}) =>
      $postTopicError && !$postTopic && '0.5em'};
  }
`;
const TextInputContainer = styled.div`
  flex: 1;
  position: relative;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;
const StyledTextInput = styled(TextInput)`
  width: 100%;
`;
const StyledHelpRoundedIcon = styled.div`
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  background-color: white;
  height: calc(100% - 4px);
  padding-left: 6px;
`;
const StyHelpRoundedIcon = styled(HelpRoundedIcon)`
  cursor: pointer;
  @media (max-width: ${isTablet}) {
    height: 1.25em;
    width: 1.25em;
  }
`;
const StyledLanguageSelect = styled(LanguageSelect)`
  @media (max-width: ${isTablet}) {
    width: 100%;
    box-sizing: border-box;
  }
`;
const IdeasContainer = styled.div`
  display: flex;
  gap: 1.5em;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
  }
`;
const StyledMainButton = styled(Button)`
  @media (max-width: ${isTablet}) {
    flex: 0.7;
  }
`;
const StyledLightButton = styled(Button)`
  @media (max-width: ${isTablet}) {
    flex: 0.3;
  }
`;
