import {Dispatch, FunctionComponent, SetStateAction, useState} from 'react';
import HelpRoundedIcon from '../../../components/atoms/Icons/HelpRoundedIcon';
import {t} from '@lingui/macro';
import styled from 'styled-components';
import EditIcon from '../../../components/atoms/Icons/EditIcon';
import {TextInput} from '../../../components/atoms/TextInput';
import LinkedInIcon from '../../../components/atoms/Icons/LinkedInIcon';
import {LanguageSelect} from '../../../components/atoms/LanguageSelect';
import {isTablet} from '../../../utils/responsive';
import {SavedPostLanguage} from '../../../hooks/savedPost/savedPost.types';
import {isValidLinkedInProfileUrl} from '../../../utils/isValidLinkedInProfileUrl';
import {getBrowserLocale} from '../../../activateLocale';
import {ProfileUrlStyleToolTip} from './ProfileUrlStyleToolTip';

interface Props {
  onGoBack: () => void;
  profileUrl: string;
  setProfileUrl: Dispatch<SetStateAction<string>>;
  postLang: SavedPostLanguage;
  setPostLang: Dispatch<SetStateAction<SavedPostLanguage>>;
  profileUrlError: string;
  isSubmitted: boolean;
  isOnboarding: boolean;
}

interface Suggestion {
  name: string;
  imageUrl: string;
  linkedInUrl: string;
}

const frCreators: Suggestion[] = [
  {
    name: 'Benoît Dubos',
    imageUrl: 'https://i.ibb.co/BrwhkbL/1695053204498-1.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/benoitdubos/',
  },
  {
    name: 'Juliette Cadot',
    imageUrl: 'https://i.ibb.co/d5PsQRM/1695654816337.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/juliettecadot/',
  },
  {
    name: 'Caroline Rousset',
    imageUrl: 'https://i.ibb.co/j3rN3n8/1662898929601-1.jpg',
    linkedInUrl:
      'https://www.linkedin.com/in/caroline-rousset-creatrice-de-contenus/',
  },
  {
    name: `Thibault Louis`,
    imageUrl: 'https://i.ibb.co/f09NRrf/1694438720863-1.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/thibault-louis/',
  },
];

const enCreators: Suggestion[] = [
  {
    name: `Justin welsh`,
    imageUrl: 'https://i.ibb.co/FqGN8LV/justin.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/justinwelsh/',
  },
  {
    name: `Mita Mallick`,
    imageUrl: 'https://i.ibb.co/bvKFvK0/mita.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/mita-mallick-2b165822/',
  },
  {
    name: `Alex Hormozi`,
    imageUrl: 'https://i.ibb.co/sQFDPD4/alex.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/alexhormozi/',
  },
  {
    name: `Shaun Robinson`,
    imageUrl: 'https://i.ibb.co/1qYCLFS/shaun.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/msshaunrobinson/',
  },
];

export const ProfileUrlStyle: FunctionComponent<Props> = ({
  onGoBack,
  profileUrl,
  setProfileUrl,
  postLang,
  setPostLang,
  profileUrlError,
  isSubmitted,
  isOnboarding,
}) => {
  const [isToolTip, setIsToolTip] = useState<boolean>(false);
  const locale = getBrowserLocale();
  const contentCreators = locale === 'fr' ? frCreators : enCreators;
  return (
    <ContentContainer $isOnboarding={isOnboarding}>
      {isOnboarding && (
        <Between>
          <P>{t`writing style`}</P>
          <Div>
            <Type>
              <StyledLinkedInIcon size={18} />
              {t`From LinkedIn profile url`}
            </Type>
            <Change onClick={onGoBack}>
              <StyledEditIcon width={20} height={20} />
              {t`Change`}
            </Change>
          </Div>
        </Between>
      )}
      <Flex
        $profileUrlError={profileUrlError}
        $profileUrl={profileUrl}
        $isOnboarding={isOnboarding}>
        <TextInputContainer
          $profileUrlError={profileUrlError}
          $profileUrl={profileUrl}>
          <StyledTextInput
            label={t`Url of LinkedIn profile`}
            placeholderText={t`Url of LinkedIn profile (you or your favourite content creator). Example: https://www.linkedin.com/in/pamartn/`}
            value={profileUrl}
            onChange={(e) => setProfileUrl(e.target.value)}
            error={
              isSubmitted &&
              !isValidLinkedInProfileUrl(profileUrl) &&
              profileUrlError !== ''
            }
            helperText={
              !isValidLinkedInProfileUrl(profileUrl) && profileUrlError
            }
          />
          <StyledHelpRoundedIcon onClick={() => setIsToolTip(!isToolTip)}>
            <StyldHelpRoundedIcon />
          </StyledHelpRoundedIcon>
          {isToolTip && <ProfileUrlStyleToolTip setIsToolTip={setIsToolTip} />}
        </TextInputContainer>
        <StyledLanguageSelect {...{postLang, setPostLang}} />
      </Flex>
      {isOnboarding && (
        <SuggestionsContainer
          $profileUrlError={profileUrlError}
          $profileUrl={profileUrl}>
          <Quest>{t`Do you want suggestions on ideas?`}</Quest>
          <Suggestions>
            {contentCreators.map((suggestion, index) => (
              <Suggestion
                key={index}
                onClick={() => setProfileUrl(suggestion.linkedInUrl)}>
                <StyledImg src={suggestion.imageUrl} alt={suggestion.name} />{' '}
                {suggestion.name}
              </Suggestion>
            ))}
          </Suggestions>
        </SuggestionsContainer>
      )}
    </ContentContainer>
  );
};

const ContentContainer = styled.div<{$isOnboarding: boolean}>`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  ${({$isOnboarding}) => $isOnboarding && 'padding: 1.5em;'}
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  ${({$isOnboarding}) => $isOnboarding && 'margin-top: -0.5em;'}
  width: 100%;
  box-sizing: border-box;
`;
const Between = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    align-items: start;
    gap: 1em;
  }
`;
const P = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  @media (max-width: ${isTablet}) {
    width: 100%;
    justify-content: space-between;
  }
`;
const Type = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 1.125em;
  font-weight: 600;
`;
const Change = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.primary4.shade1};
  font-size: 0.875em;
  font-weight: 600;
  cursor: pointer;
`;
const TextInputContainer = styled.div<{
  $profileUrlError: string;
  $profileUrl: string;
}>`
  flex: 1;
  position: relative;
  @media (max-width: ${isTablet}) {
    margin-bottom: ${({$profileUrlError, $profileUrl}) =>
      $profileUrlError && !isValidLinkedInProfileUrl($profileUrl) && `1.5em`};
    width: 100%;
  }
`;
const StyledTextInput = styled(TextInput)`
  width: 100%;
`;
const StyledHelpRoundedIcon = styled.div`
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  padding-left: 6px;
  background-color: white;
  height: calc(100% - 4px);
`;
const SuggestionsContainer = styled.div<{
  $profileUrlError: string;
  $profileUrl: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  @media (min-width: ${isTablet}) {
    margin-top: ${({$profileUrlError, $profileUrl}) =>
      $profileUrlError && !isValidLinkedInProfileUrl($profileUrl) && `0.25em`};
  }
`;
const Quest = styled.span`
  color: ${({theme}) => theme.colors.primary4.shade10};
  font-size: 0.75em;
  letter-spacing: 0.0125em;
`;
const Suggestions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
`;
const Suggestion = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade4};
  border-radius: 3.125em;
  padding: 0.25em;
  padding-right: 1em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 600;
  font-size: 0.75em;
  letter-spacing: 0.0125em;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const StyledImg = styled.img`
  height: 1.75em;
  width: 1.75em;
  border-radius: 50%;
`;
const Flex = styled.div<{
  $profileUrlError: string;
  $profileUrl: string;
  $isOnboarding: boolean;
}>`
  display: flex;
  gap: 1em;
  @media (min-width: ${isTablet}) {
    ${({$isOnboarding, $profileUrlError, $profileUrl}) =>
      !$isOnboarding &&
      $profileUrlError &&
      !isValidLinkedInProfileUrl($profileUrl) &&
      `margin-bottom: 1em;`}
  }
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    gap: 1.5em;
  }
`;
const StyledEditIcon = styled(EditIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
const StyledLinkedInIcon = styled(LinkedInIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
const StyldHelpRoundedIcon = styled(HelpRoundedIcon)`
  cursor: pointer;
  @media (max-width: ${isTablet}) {
    height: 1.45em;
    width: 1.45em;
  }
`;
const StyledLanguageSelect = styled(LanguageSelect)`
  @media (max-width: ${isTablet}) {
    width: 100%;
    box-sizing: border-box;
  }
`;
