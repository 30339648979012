import {FunctionComponent, useEffect, useState} from 'react';
import {Page} from '../../components/templates/Page';
import styled from 'styled-components';
import {RedactPostStyle, RedactStyleSource} from './components/RedactPostStyle';
import {usePostToneOptions} from '../../hooks/usePostToneOptions';
import useDefaultLanguage from '../../hooks/postLang/useDefaultLanguage';
import {
  RedactContentSource,
  RedactPostContent,
} from './components/RedactPostContent';
import {t} from '@lingui/macro';
import {Button} from '../../components/atoms/Button';
import {isTablet} from '../../utils/responsive';
import ArrowRight from '../../components/atoms/Icons/ArrowRightIcon';
import {useRedactPost} from '../../hooks/redactPost/useRedactPost';
import {RedactPostGeneration} from './components/RedactPostGeneration';
import {useLocation, useNavigate} from 'react-router-dom';
import {PricingPopup} from '../../components/organisms/PricingPopup';
import {resetErrors, validateForm} from '../../utils/validateForm';
import {
  ContentFromIdeas,
  ContentFromUrl,
  PostContentSource,
  PostStyleSource,
  PostToRedact,
  StyleFromProfile,
  StyleFromTone,
} from '../../hooks/redactPost/redactPost.types';

export const RedactPost: FunctionComponent = () => {
  const postToneOptions = usePostToneOptions();
  const [postIdeas, setPostIdeas] = useState<string>('');
  const [postSubject, setPostSubject] = useState<string>('');
  const [postTone, setPostTone] = useState(postToneOptions[0].value);
  const [postLang, setPostLang] = useDefaultLanguage();
  const [contentUrl, setContentUrl] = useState<string>('');
  const [profileUrl, setProfileUrl] = useState<string>('');
  const [contentSource, setContentSource] = useState<RedactContentSource>(
    RedactContentSource.MY_OWN_IDEAS,
  );
  const [styleSource, setStyleSource] = useState<RedactStyleSource>(
    RedactStyleSource.PROFILE_URL,
  );
  const [readyToRedact, setReadyToRedact] = useState<boolean>(false);
  const [postSubjectError, setPostSubjectError] = useState<string>('');
  const [postSubjectUrlError, setPostSubjectUrlError] = useState<string>('');
  const [contentUrlError, setContentUrlError] = useState<string>('');
  const [profileUrlError, setProfileUrlError] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const defaultLanguage = useDefaultLanguage()[0];
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const parseQueryParams = (query: string) => new URLSearchParams(query);
    const queryParams = parseQueryParams(location.search);
    const storedData = localStorage.getItem('redactPostFormParameters');

    if (queryParams.has('subject') || queryParams.has('ideas')) {
      setPostSubject(queryParams.get('subject') || '');
      setPostIdeas(queryParams.get('ideas')?.replace(/::/g, '\n') ?? '');
      return;
    }
    if (storedData) {
      if (queryParams.get('isNewSubscription') !== 'true') {
        return;
      }
      const data = JSON.parse(
        localStorage.getItem('redactPostFormParameters') || '{}',
      ) as PostToRedact;
      setPostSubject(data.subject);
      setPostIdeas(
        data.contentSource === PostContentSource.IDEAS
          ? (data.content as ContentFromIdeas).ideas
          : '',
      );
      setContentSource(
        data.contentSource === PostContentSource.URL
          ? RedactContentSource.FROM_AN_URL
          : RedactContentSource.MY_OWN_IDEAS,
      );
      setContentUrl(
        data.contentSource === PostContentSource.URL
          ? (data.content as ContentFromUrl).url
          : '',
      );
      setStyleSource(
        data.styleSource === PostStyleSource.PROFILE
          ? RedactStyleSource.PROFILE_URL
          : RedactStyleSource.POST_TYPE,
      );
      setProfileUrl(
        data.styleSource === PostStyleSource.PROFILE
          ? (data.style as StyleFromProfile).url
          : '',
      );
      setPostTone(
        data.styleSource === PostStyleSource.TONE
          ? (data.style as StyleFromTone).tone
          : postToneOptions[0].value,
      );
      setPostLang(data.language);
    }
  }, [
    location.search,
    postToneOptions,
    setContentUrl,
    setPostIdeas,
    setPostLang,
    setPostSubject,
  ]);
  const {
    onGeneratePostSubmit,
    redactedPosts: posts,
    isLoading,
    showPricingPopup,
    setShowPricingPopup,
    error,
  } = useRedactPost(
    postSubject,
    postIdeas,
    contentUrl,
    contentSource === RedactContentSource.FROM_AN_URL,
    styleSource === RedactStyleSource.PROFILE_URL,
    profileUrl,
    postTone,
    postLang,
  );

  const onWritePost = () => {
    setIsSubmitted(true);
    if (
      validateForm(
        contentUrl,
        postSubject,
        profileUrl,
        setContentUrlError,
        setPostSubjectError,
        setProfileUrlError,
        setPostSubjectUrlError,
        undefined,
        contentSource,
        styleSource,
      )
    ) {
      return;
    }
    resetErrors(
      setContentUrlError,
      setPostSubjectError,
      setProfileUrlError,
      setPostSubjectUrlError,
      setIsSubmitted,
    );
    onGeneratePostSubmit();
    setReadyToRedact(true);
  };

  const onReset = () => {
    setPostIdeas('');
    setPostSubject('');
    setContentUrl('');
    setProfileUrl('');
    setPostTone(postToneOptions[0].value);
    setPostLang(defaultLanguage);
    setContentSource(RedactContentSource.MY_OWN_IDEAS);
    setStyleSource(RedactStyleSource.PROFILE_URL);
    setReadyToRedact(false);
    resetErrors(
      setContentUrlError,
      setPostSubjectError,
      setProfileUrlError,
      setPostSubjectUrlError,
      setIsSubmitted,
    );
  };

  useEffect(() => {
    if (showPricingPopup || error) {
      setReadyToRedact(false);
    }
  }, [showPricingPopup, error]);

  return (
    <Page>
      {readyToRedact ? (
        <RedactPostGeneration
          posts={posts}
          contentSource={contentSource}
          styleSource={styleSource}
          onChangeInputs={() => {
            setReadyToRedact(false);
          }}
          onNewPost={onReset}
          isLoading={isLoading}
        />
      ) : (
        <Container>
          <Header>
            <Title>{t`Create a post`}</Title>
            <NoIdea>
              {t`No idea?`}{' '}
              <FindIdeaLink onClick={() => navigate('/findIdea')}>
                {t`Find an idea here →`}
              </FindIdeaLink>
            </NoIdea>
          </Header>
          <RedactPostContent
            contentSource={contentSource}
            setContentSource={setContentSource}
            postSubject={postSubject}
            setPostSubject={setPostSubject}
            postIdeas={postIdeas}
            setPostIdeas={setPostIdeas}
            contentUrl={contentUrl}
            setContentUrl={setContentUrl}
            contentUrlError={contentUrlError}
            postSubjectError={postSubjectError}
            postSubjectUrlError={postSubjectUrlError}
            isSubmitted={isSubmitted}
          />
          <RedactPostStyle
            styleSource={styleSource}
            setStyleSource={setStyleSource}
            setPostTone={setPostTone}
            postTone={postTone}
            postLang={postLang}
            setPostLang={setPostLang}
            profileUrl={profileUrl}
            setProfileUrl={setProfileUrl}
            profileUrlError={profileUrlError}
            isSubmitted={isSubmitted}
          />
          <End>
            <Button
              label={t`Reset`}
              size="medium"
              variant="light"
              onClick={onReset}
            />
            <Button
              onClick={onWritePost}
              label={t`Write my post`}
              size="medium"
              variant="main"
              endIcon={<StyledArrowRight width={22} height={23} />}
            />
          </End>
        </Container>
      )}
      {showPricingPopup && (
        <PricingPopup onClose={() => setShowPricingPopup(false)} />
      )}
    </Page>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: min(1100px, 80%);
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 1.5em;
  font-weight: 700;
  margin: 0;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const NoIdea = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
`;

const FindIdeaLink = styled.span`
  color: ${({theme}) => theme.colors.primary4.shade1};
  cursor: pointer;
  text-decoration: underline;
`;

const End = styled.div`
  width: 100%;
  display: flex;
  gap: 1.25em;
  justify-content: end;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;

const StyledArrowRight = styled(ArrowRight)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
