import {FunctionComponent} from 'react';
import {Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import styled from 'styled-components';
import ChevronBottom from '../../../components/atoms/Icons/ChevronBottom';

interface Props {
  faqs: {question: string; answer: string}[];
}

export const FAQ: FunctionComponent<Props> = ({faqs}) => {
  return (
    <FAQContainer>
      <FAQTitle>Frequently Asked Questions</FAQTitle>
      <FAQList>
        {faqs.map((faq, index) => (
          <StyledAccordion key={index}>
            <StyledAccordionSummary expandIcon={<ChevronBottom />}>
              {faq.question}
            </StyledAccordionSummary>
            <StyledAccordionDetails>{faq.answer}</StyledAccordionDetails>
          </StyledAccordion>
        ))}
      </FAQList>
    </FAQContainer>
  );
};

const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60em;
  margin: 0 auto;
`;

const FAQTitle = styled.span`
  font-weight: 700;
  font-size: 2em;
  color: ${({theme}) => theme.colors.primary1.shade2};
  margin-bottom: 1em;
`;

const FAQList = styled.div`
  margin-top: 1em;
`;

const StyledAccordion = styled(Accordion)`
  margin-bottom: 1em;
  box-shadow: none;
  border-radius: 1em;
  border: 1px solid ${({theme}) => theme.colors.neutral.shade6};

  &:before {
    display: none;
  }

  &:first-child {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
  }

  &:last-child {
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  font-size: 1.1em;
  font-weight: 700;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: break-word;
`;
