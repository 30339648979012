import {FunctionComponent} from 'react';
import styled from 'styled-components';
import HeadlineGeneratorScreenshot1 from '../../images/HowTo_HeadlineGenerator_1.png';
import HeadlineGeneratorScreenshot2 from '../../images/HowTo_HeadlineGenerator_2.png';

export const HowToUseHeadlineGenerator: FunctionComponent = () => {
  return (
    <TutoContainer>
      <TutoList>
        <Div>
          <Title>Steps to Create LinkedIn Headlines Using RedactAI</Title>
          <Text>{`The process is simple. Enter the necessary information in the field labeled "I am a..." and press the Generate button.`}</Text>
          <StyledImage
            src={HeadlineGeneratorScreenshot1}
            alt="Create Your LinkedIn Headline"
          />
        </Div>
        <Div>
          <Text>{`For a standout headline, provide more details beyond just your job title and company name.`}</Text>
          <StyledImage
            src={HeadlineGeneratorScreenshot2}
            alt="Create Your LinkedIn Headline"
          />
        </Div>
        <Div>
          <Title>{`If you're happy with the result, copy your new headline and use it on LinkedIn. To make another attempt, click the Try Again button.`}</Title>
          <SubTitle>{`Try using one of these templates for optimal results:`}</SubTitle>
          <StyledList>
            <ListItem>{`I am a [job title], and I assist [your clients] in achieving [dream outcome].`}</ListItem>
            <ListItem>{`I work as a [job title] at [company], driving [impact/change] in [industry/field].`}</ListItem>
            <ListItem>{`I am a [job title], providing [solution/benefit] to [target audience/industry].`}</ListItem>
          </StyledList>
          <SubTitle>{`If you're an entrepreneur, consider these templates:`}</SubTitle>
          <StyledList>
            <ListItem>{`Founder of [company name], offering solutions for [challenge/problem] in [industry/field].`}</ListItem>
            <ListItem>{`Entrepreneur transforming [industry/field] with [unique approach/product].`}</ListItem>
          </StyledList>
          <Title>
            Guidelines for Crafting a Professional LinkedIn Headline
          </Title>
          <StyledList>
            <ListItem>{`Keep it concise. Clearly outline your role, profession, or area of expertise without extra words.`}</ListItem>
            <ListItem>{`Emphasize your strengths. Highlight what differentiates you or what value you bring to your company, industry, or clients.`}</ListItem>
            <ListItem>{`Include accomplishments. If relevant, add measurable results or achievements to showcase your expertise and success.`}</ListItem>
            <ListItem>{`Show your personality. Let your LinkedIn headline reflect your character—whether it's through humor, creativity, or passion.`}</ListItem>
            <ListItem>{`Boost your visibility. Incorporate keywords related to your industry, skills, or desired job roles to improve your search rankings on LinkedIn.`}</ListItem>
            <ListItem>{`Keep it updated. Regularly refresh your headline to reflect new career developments or milestones.`}</ListItem>
          </StyledList>
          <Title>The Importance of a LinkedIn Headline</Title>
          <StyledList>
            <ListItem>{`Make a great first impression. Your headline is among the first things people notice on your profile, so make it memorable and engaging.`}</ListItem>
            <ListItem>{`Enhance your professional brand. Your LinkedIn headline is a critical part of your professional image, influencing how others perceive you and potentially leading to career growth, collaborations, or business partnerships.`}</ListItem>
            <ListItem>{`Improve search results. Recruiters and potential partners often search for specific roles or skills. A well-crafted headline can help your profile appear in top search results.`}</ListItem>
            <ListItem>{`Stand out from the crowd. A strong headline allows you to showcase what sets you apart from others, whether it's your skills, achievements, or professional interests.`}</ListItem>
            <ListItem>{`Create networking opportunities. A clear and specific headline helps others understand how you can provide value, leading to potential connections and opportunities.`}</ListItem>
          </StyledList>
        </Div>
      </TutoList>
    </TutoContainer>
  );
};

const TutoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60em;
  margin: 0 auto;
  margin-top: 3em;
`;

const TutoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  justify-content: center;
  text-align: center;
`;

const Title = styled.span`
  font-size: 1.35em;
  font-weight: 700;
`;

const SubTitle = styled.span`
  font-size: 1.2em;
  font-weight: 600;
  margin-top: 0.5em;
`;

const Text = styled.span`
  font-size: 1.15em;
  line-height: 1.5em;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const StyledImage = styled.img`
  height: 60%;
  width: 60%;
  object-fit: contain;
  align-self: center;
`;

const StyledList = styled.ul`
  list-style-type: disc;
  padding-left: 1.5em;
  text-align: left;
  margin: 0.5em 0;
`;

const ListItem = styled.li`
  font-size: 1.1em;
  line-height: 1.4em;
  margin-bottom: 0.5em;
`;
