import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {isTablet} from '../../../utils/responsive';
import {RedactedPost} from '../../../hooks/redactPost/useRedactPostMutation';
import {Button} from '../../../components/atoms/Button';
import PlusIcon from '../../../components/atoms/Icons/PlusIcon';
import {ChatWithRedactAIChatArea} from '../../../components/organisms/ChatWithRedactAIChatArea';
import {useSubscription} from '../../../hooks/subscription/useSubscription';

interface Props {
  posts: RedactedPost[];
  onChangeInputs: () => void;
  onNewPost: () => void;
}

export const RedactPostChatWithAi: FunctionComponent<Props> = ({
  posts,
  onChangeInputs,
  onNewPost,
}) => {
  const {subscription} = useSubscription();
  return (
    <Parent>
      <TopContainer>
        <HeaderContainer>
          <SubTitle>
            {t`Please like or dislike the posts to improve our`}{' '}
            <span>{t`IA model`}</span>
          </SubTitle>
          <ButtonContainer>
            <StyledButton
              as={StyledLightButton}
              label={t`Change my inputs`}
              size="small"
              variant="light"
              onClick={onChangeInputs}
            />
            <StyledButton
              as={StyledMainButton}
              label={t`New post`}
              size="small"
              variant="main"
              startIcon={<PlusIcon />}
              onClick={onNewPost}
            />
          </ButtonContainer>
        </HeaderContainer>
      </TopContainer>
      <ChatWithRedactAIChatArea
        isOnboarding={false}
        posts={posts}
        isChatWithRedactAI={subscription && subscription.product.credits > 25}
      />
    </Parent>
  );
};

const Parent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 97vh;
  justify-content: center;
  width: 100%;
  row-gap: 1em;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledMainButton = styled(Button)`
  width: 14em;
  @media (max-width: ${isTablet}) {
    flex: 0.7;
  }
`;

const StyledLightButton = styled(Button)`
  width: 14em;
  @media (max-width: ${isTablet}) {
    flex: 0.3;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  color: ${({theme}) => theme.colors.neutral.shade11};
  width: 100%;
  gap: 1em;

  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1.25em;
  width: 100%;
`;

const StyledButton = styled(Button)`
  flex: 1;
  @media (min-width: ${isTablet}) {
    flex: 0 0 auto;
    width: 14em;
  }
`;

const SubTitle = styled.p`
  font-size: 1em;
  font-weight: 500;
  color: ${({theme}) => theme.colors.neutral.shade9};
  align-self: flex-start;
  width: 100%;
  span {
    color: ${({theme}) => theme.colors.neutral.shade11};
    font-weight: 700;
  }
  @media (max-width: ${isTablet}) {
    align-self: center;
  }
`;
