import {FunctionComponent, useEffect, useRef, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import RedactAILogo from '../../../components/atoms/Images/RedactAILogo';
import {blogs, tools} from '../utils/FooterLinks';
import {isTablet} from '../../../utils/responsive';
import ChevronBottom from '../../../components/atoms/Icons/ChevronBottom';
import {Link} from 'react-router-dom';
import {Button} from '../../../components/atoms/Button';

export const HeaderNavbar: FunctionComponent = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Navbar>
      <StyledRedactAILogo
        onClick={() => window.open('https://www.redactai.io/')}
      />
      <DropdownContainer ref={dropdownRef}>
        <TipsHeader onClick={() => setDropdownOpen(!dropdownOpen)}>
          Discover More from RedactAI
          <ChevronIcon $isOpen={dropdownOpen}>
            <ChevronBottom width={11} height={11} />
          </ChevronIcon>
        </TipsHeader>
        {dropdownOpen && (
          <DropdownMenu>
            <ContentContainer>
              <ToolsSection>
                <SectionHeader>
                  🛠 Explore our free LinkedIn tools
                </SectionHeader>
                <div>
                  {tools.map((tool, index) => (
                    <Item key={index} to={tool.link}>
                      <ItemName>{tool.name}</ItemName>
                      <ItemDescription>{tool.description}</ItemDescription>
                    </Item>
                  ))}
                </div>
              </ToolsSection>
              <BlogSection>
                <SectionHeader>
                  📚 Check out our free LinkedIn resources
                </SectionHeader>
                <div>
                  {blogs.map((blog, index) => (
                    <Item key={index} to={blog.link}>
                      <ItemName>{blog.name}</ItemName>
                      <ItemDescription>{blog.description}</ItemDescription>
                    </Item>
                  ))}
                </div>
              </BlogSection>
            </ContentContainer>
            <ButtonSection>
              <Button
                onClick={() => window.open('https://www.redactai.io/')}
                size="medium"
                variant="main"
                label="Discover Redact AI"
              />
              <ButtonSectionText>
                Enhance your LinkedIn profile to build your personal brand and
                attract new opportunities.
              </ButtonSectionText>
            </ButtonSection>
          </DropdownMenu>
        )}
      </DropdownContainer>
    </Navbar>
  );
};

const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em 5em;
  @media (max-width: ${isTablet}) {
    padding: 2em 1em;
  }
`;

const StyledRedactAILogo = styled(RedactAILogo)`
  cursor: pointer;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  margin-top: 3em;
`;

const ButtonSectionText = styled.span`
  font-weight: 500;
  font-size: 1em;
  text-align: center;
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const popOut = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 150%;
  right: 0;
  background-color: white;
  border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
  border-radius: 1em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  height: 40em;
  overflow-y: auto;
  width: 45em;
  padding: 2em;
  animation: ${popOut} 0.3s ease-out;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;

  @media (max-width: ${isTablet}) {
    width: 30em;
    height: 25em;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2em;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
  }
`;

const ToolsSection = styled.div`
  flex: 1;
`;

const BlogSection = styled.div`
  flex: 1;
`;

const SectionHeader = styled.h3`
  font-size: 1.4em;
  margin-bottom: 1em;
`;

const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 0.75em;
  padding: 0.5em;
  text-decoration: none;
  color: ${({theme}) => theme.colors.neutral.shade11};
  &:hover {
    background-color: ${({theme}) => theme.colors.primary1.shade8};
  }
`;

const ItemName = styled.span`
  font-weight: 600;
  font-size: 1em;
  line-height: 1.33em;
`;

const ItemDescription = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade10};
  line-height: 1.33em;
  font-size: 0.85em;
`;

const TipsHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  cursor: pointer;
  text-align: left;
`;

const ChevronIcon = styled.div<{$isOpen: boolean}>`
  margin-left: 0.5em;
  transition: transform 0.1s ease;
  transform: ${({$isOpen}) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
