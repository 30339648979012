import {FunctionComponent, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import {isTablet} from '../../../utils/responsive';
import CopyIcon from '../../../components/atoms/Icons/CopyIcon';
import {t} from '@lingui/macro';
import {getInitials} from '../../../utils/getInitials';
import {MobileIcon} from '../../../components/atoms/Icons/MobileIcon';
import {LightTab} from '../../../components/atoms/LightTab';
import {DesktopIcon} from '../../../components/atoms/Icons/DesktopIcon';
import DoneIcon from '../../../components/atoms/Icons/DoneIcon';
import {CloseButton} from '../../../components/atoms/CloseButton';
import {usePostPreview} from '../../../hooks/redactPost/usePostPreview';
import {Button} from '../../../components/atoms/Button';
import {formatCreatedAt} from '../../../utils/formatCreatedAt';
import {CircularProgress} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {Editor} from '../../../pages/freeTools/components/Editor';

interface Props {
  setShowPreviewPopup: (show: boolean) => void;
  name: string;
  initialText: string;
  postId: string;
  createdAt: string | Date;
  onTextChange: (text: string) => void;
  invalidateQueries?: boolean;
}

export const PreviewPopup: FunctionComponent<Props> = ({
  setShowPreviewPopup,
  name,
  initialText,
  postId,
  createdAt,
  onTextChange,
  invalidateQueries = false,
}) => {
  const queryClient = useQueryClient();
  const {
    showFullText,
    postText,
    isLaptopPreview,
    wordCount,
    toggleShowFullText,
    setIsLaptopPreview,
    handleCopy,
    loading,
    setPostText,
  } = usePostPreview(initialText, postId);

  useEffect(() => {
    if (onTextChange) {
      onTextChange(postText);
    }
  }, [postText, onTextChange]);

  return (
    <PreviewPopupContainer>
      <PopupContainer>
        <CloseButtonWrapper
          onClick={() => {
            invalidateQueries &&
              queryClient.invalidateQueries({queryKey: ['savedPosts']});
            setShowPreviewPopup(false);
          }}>
          <CloseButton />
        </CloseButtonWrapper>
        <Header>
          <StrongText>{t`Post preview`}</StrongText>
          <Row>
            <p>
              {wordCount} {wordCount > 1 ? t`Words` : t`Word`}
            </p>
            <AutoSaveStatus>
              {loading ? (
                <StyledCircularProgress size={'1.25em'} />
              ) : (
                <DoneIcon />
              )}
              <p>{t`Automatically saved`}</p>
            </AutoSaveStatus>
          </Row>
        </Header>
        <Flex>
          <LeftContainer>
            <Editor setPostText={setPostText} postText={postText} />
          </LeftContainer>
          <RightContainer>
            <Row>
              <LightTab
                isSelected={isLaptopPreview}
                label={t`Web`}
                icon={<DesktopIcon />}
                onClick={() => setIsLaptopPreview(true)}
              />
              <LightTab
                isSelected={!isLaptopPreview}
                label={t`Mobile`}
                icon={<MobileIcon />}
                onClick={() => setIsLaptopPreview(false)}
              />
            </Row>
            <PreviewContainer $isLaptopPreview={isLaptopPreview}>
              <AvatarSection>
                <AvatarWrapper>
                  <Avatar>{getInitials(name)}</Avatar>
                </AvatarWrapper>
                <UserInfo>
                  <UserName>{name}</UserName>
                  <UserSubtitle>{t`I use RedactAI because I'm smart`}</UserSubtitle>
                  <TimeAgo>
                    {formatCreatedAt(createdAt)}
                    <Dot />
                  </TimeAgo>
                </UserInfo>
              </AvatarSection>
              <Text>
                {!showFullText ? (
                  <PostText $isLaptopPreview={isLaptopPreview}>
                    {postText}
                    <SeeMoreText onClick={toggleShowFullText}>
                      {t`...see more`}
                    </SeeMoreText>
                  </PostText>
                ) : (
                  <div>
                    {postText}
                    <SeeLessText onClick={toggleShowFullText}>
                      {t`See less`}
                    </SeeLessText>
                  </div>
                )}
              </Text>
            </PreviewContainer>
            <ButtonWrapper>
              <div />
              <StyledButton
                onClick={handleCopy}
                variant="light"
                size="small"
                startIcon={<CopyIcon />}
                label={t`Copy`}
              />
            </ButtonWrapper>
          </RightContainer>
        </Flex>
      </PopupContainer>
    </PreviewPopupContainer>
  );
};

const opacity = keyframes`
  from {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
`;
const PreviewPopupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000099;
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 60;
  animation: ${opacity} 0.2s ease-out;
`;

const PopupContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  padding: 2em;
  position: relative;
  min-width: 20em;
  @media (max-width: ${isTablet}) {
    padding-top: 3em;
    padding-bottom: 3em;
  }
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin-bottom: 2em;
  @media (max-width: ${isTablet}) {
    align-items: center;
  }
`;
const StrongText = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 700;
  font-size: 2.75em;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
`;
const AutoSaveStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375em;
`;
const Flex = styled.div`
  display: flex;
  gap: 2em;
`;
const LeftContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  width: 30em;
  height: 37.5em;
  overflow: hidden;
  box-sizing: border-box;
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
const Text = styled.span`
  line-height: 1.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  letter-spacing: 0.03125em;
  white-space: pre-wrap;
  word-wrap: break-word;
`;
const RightContainer = styled.div`
  border-radius: 1.5em;
  padding: 0.75em;
  background-color: ${({theme}) => theme.colors.neutral.shade13};
  border: 1px solid ${({theme}) => theme.colors.neutral.shade6};
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  height: 37.5em;
  box-shadow: 0px 7px 27px 0px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  width: 36em;
`;
const PreviewContainer = styled.div<{$isLaptopPreview: boolean}>`
  flex: 1;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  padding: 1.5em;
  border-radius: 1em;
  overflow-y: scroll;
  box-sizing: border-box;
  width: ${({$isLaptopPreview}) => ($isLaptopPreview ? '100%' : '80%')};
  margin: 0 auto;
  &::-webkit-scrollbar {
    display: none;
  }
  white-space: pre-wrap;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  transition: all 0.3s;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledButton = styled(Button)`
  width: fit-content;
`;
const AvatarWrapper = styled.div`
  height: 3.6em;
  width: 3.6em;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.neutral.shade5};
  margin-top: 0.15em;
`;
const Avatar = styled.p`
  font-size: 1.25em;
  font-weight: 700;
`;
const UserInfo = styled.div`
  color: #000000;
  font-size: 1em;
`;
const UserName = styled.p`
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.0125em;
  margin: 0;
`;
const UserSubtitle = styled.p`
  font-size: 0.8125em;
  letter-spacing: 0.00625em;
  opacity: 60%;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
`;
const TimeAgo = styled.span`
  font-size: 0.8em;
  opacity: 60%;
  display: flex;
  align-items: center;
`;
const Dot = styled.span`
  width: 0.3em;
  height: 0.3em;
  background-color: ${({theme}) => theme.colors.neutral.shade11};
  border-radius: 50%;
  margin-left: 0.5em;
`;
const AvatarSection = styled.div`
  display: flex;
  gap: 0.9375em;
`;
const PostText = styled.div<{$isLaptopPreview: boolean}>`
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: ${({$isLaptopPreview}) => ($isLaptopPreview ? 4 : 2)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const SeeLessText = styled.span`
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: block;
  margin-top: 0.5em;
`;
const SeeMoreText = styled.span`
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  padding-left: 10px;
  cursor: pointer;
`;
const StyledCircularProgress = styled(CircularProgress)`
  color: ${({theme}) => theme.colors.primary3.shade2};
`;
