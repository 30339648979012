import {FunctionComponent} from 'react';
import LinkedInIcon from '../../../components/atoms/Icons/LinkedInIcon';
import styled from 'styled-components';

export const LinkedInHeader: FunctionComponent = () => {
  return (
    <LinkedInText>
      Linked <LinkedInIcon size={60} />
    </LinkedInText>
  );
};
const LinkedInText = styled.span`
  display: flex;
  align-items: center;
`;
