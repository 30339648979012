import {
  FunctionComponent,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import BackgroundBlur from '../../components/molecules/images/BackgroundBlur.png';
import styled from 'styled-components';
import {
  ReferralSourceForm,
  UserReferralSource,
} from '../../components/organisms/ReferralSourceForm';
import {RedactaiIcon} from '../../components/atoms/Icons/RedactaiIcon';
import {t} from '@lingui/macro';
import {
  ChooseContentSource,
  ContentSource,
} from './components/ChooseContentSource';
import {LearnHowToGenerateContentContainer} from './components/LearnHowToGenerateContentContainer';
import {GenerateFromNoIdeas} from './components/GenerateFromNoIdeas';
import {GenerateFromContentURL} from './components/GenerateFromContentURL';
import {GenerateFromMyOwnIdeas} from './components/GenerateFromMyOwnIdeas';
import {ChooseStyleSource, StyleSource} from './components/ChooseStyleSource';
import {ProfileUrlStyle} from './components/ProfileUrlStyle';
import {PostStyle} from './components/PostStyle';
import {isTablet} from '../../utils/responsive';
import {PostGeneration} from './components/PostGeneration';
import {useRedactPost} from '../../hooks/redactPost/useRedactPost';
import useDefaultLanguage from '../../hooks/postLang/useDefaultLanguage';
import {usePostToneOptions} from '../../hooks/usePostToneOptions';
import {useTracking} from '../../hooks/useTracking';
import {UserContext} from '../../common/UserContext/user.context';
import {useNavigate} from 'react-router-dom';
import {CircularLoader} from '../../components/atoms/CircularLoader';
import {resetErrors, validateForm} from '../../utils/validateForm';
import {emailVerificationCheck} from '../../utils/emailVerificationCheck';

export interface OnboardingSteps {
  userReferralSource?: UserReferralSource;
  contentSource?: ContentSource;
  writingStyle?: StyleSource;
  hasFilledContent: boolean;
  finalStep?: boolean;
}

enum OnboardingActionType {
  SET_USER_REFERRAL_SOURCE = 'SET_USER_REFERRAL_SOURCE',
  CHOOSE_CONTENT_SOURCE = 'CHOOSE_CONTENT_SOURCE',
  FILL_IN_CONTENT = 'FILL_IN_CONTENT',
  FILL_IN_CONTENT_BACK = 'FILL_IN_CONTENT_BACK',
  CHOOSE_WRITING_STYLE = 'CHOOSE_WRITING_STYLE',
  RESET = 'RESET',
  FINAL_STEP = 'FINAL_STEP',
  FINAL_STEP_RESET = 'FINAL_STEP_RESET',
  CHOOSE_CONTENT_SOURCE_NO_IDEAS = 'CHOOSE_CONTENT_SOURCE_NO_IDEAS',
}

interface OnboardingAction {
  type: OnboardingActionType;
  userReferralSource?: UserReferralSource;
  contentSource?: ContentSource;
  writingStyle?: StyleSource;
}

const onboardingStepReducer = (
  state: OnboardingSteps,
  action: OnboardingAction,
): OnboardingSteps => {
  switch (action.type) {
    case 'SET_USER_REFERRAL_SOURCE':
      return {
        ...state,
        userReferralSource: action.userReferralSource,
      };
    case 'CHOOSE_CONTENT_SOURCE':
      return {
        ...state,
        contentSource: action.contentSource,
      };
    case 'FILL_IN_CONTENT':
      return {
        ...state,
        hasFilledContent: true,
      };
    case 'FILL_IN_CONTENT_BACK':
      return {
        ...state,
        hasFilledContent: false,
      };
    case 'CHOOSE_WRITING_STYLE':
      return {
        ...state,
        writingStyle: action.writingStyle,
      };
    case 'RESET':
      return {
        hasFilledContent: false,
      };
    case 'FINAL_STEP':
      return {
        ...state,
        finalStep: true,
      };
    case 'FINAL_STEP_RESET':
      return {
        ...state,
        finalStep: false,
      };
    case 'CHOOSE_CONTENT_SOURCE_NO_IDEAS':
      return {
        hasFilledContent: false,
        userReferralSource: action.userReferralSource,
        contentSource: action.contentSource,
      };
    default:
      return state;
  }
};

export const Onboarding: FunctionComponent = () => {
  const {trackEvent, setSelectedContentSource, setSelectedStyleSource} =
    useTracking();
  const postToneOptions = usePostToneOptions();
  const [postLang, setPostLang] = useDefaultLanguage();
  const navigate = useNavigate();

  const [postSubject, setPostSubject] = useState<string>('');
  const {me, loading, refetch} = useContext(UserContext);
  const [postIdeas, setPostIdeas] = useState<string>('');
  const [postSubjectError, setPostSubjectError] = useState<string>('');
  const [postSubjectUrlError, setPostSubjectUrlError] = useState<string>('');
  const [contentUrlError, setContentUrlError] = useState<string>('');
  const [profileUrlError, setProfileUrlError] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [contentUrl, setContentUrl] = useState('');
  const [profileUrl, setProfileUrl] = useState('');
  const [postTone, setPostTone] = useState(postToneOptions[0].value);
  const [stepState, dispatchSteps] = useReducer(onboardingStepReducer, {
    hasFilledContent: false,
  });
  const {
    onGeneratePostSubmit,
    redactedPosts: posts,
    isLoading,
    error,
  } = useRedactPost(
    postSubject,
    postIdeas,
    contentUrl,
    stepState.contentSource === ContentSource.FROM_AN_URL,
    stepState.writingStyle === StyleSource.OTHERS_PROFILE_URL ||
      stepState.writingStyle === StyleSource.PROFILE_URL,
    profileUrl,
    postTone,
    postLang,
  );

  useEffect(() => {
    trackEvent('Onboarding - Start');
  }, []);

  useEffect(() => {
    if (error) {
      dispatchSteps({
        type: OnboardingActionType.FINAL_STEP_RESET,
      });
    }
  }, [error]);

  useEffect(() => {
    if (!me) {
      refetch();
    }
    if (me?.hasFinishedOnboarding) {
      navigate('/redactPost');
      return;
    }
  }, [me, loading, navigate, refetch]);

  useEffect(() => {
    const verifyEmail = async () => {
      await emailVerificationCheck(navigate);
    };

    verifyEmail();
  }, [navigate]);

  if (!me || loading) {
    return <CircularLoader />;
  }

  return (
    <Container>
      {!stepState.userReferralSource ? (
        <Content>
          <SubContent>
            <StyledRedactaiIcon />
            <Welcome>
              {t`Good to have you,`} <StrongText>{me?.firstName} 👋</StrongText>
            </Welcome>
            <Question>{t`Only one question before crafting your first post!`}</Question>
          </SubContent>
          <ReferralSourceForm
            setUserReferralSource={(userReferralSource) => {
              dispatchSteps({
                type: OnboardingActionType.SET_USER_REFERRAL_SOURCE,
                userReferralSource,
              });
              trackEvent('Onboarding - Click - Choose referral source', {
                userReferralSource: userReferralSource,
              });
            }}
          />
        </Content>
      ) : !stepState.contentSource ? (
        <ChooseContentSource
          chooseContentSource={(contentSource) => {
            setSelectedContentSource(contentSource);
            trackEvent('Onboarding - Click - Choose content source', {
              contentSource: contentSource,
            });
            {
              contentSource === ContentSource.I_DONT_HAVE_IDEAS &&
              stepState.writingStyle
                ? dispatchSteps({
                    type: OnboardingActionType.CHOOSE_CONTENT_SOURCE_NO_IDEAS,
                    contentSource,
                    userReferralSource: stepState.userReferralSource,
                  })
                : dispatchSteps({
                    type: OnboardingActionType.CHOOSE_CONTENT_SOURCE,
                    contentSource,
                  });
            }
          }}
        />
      ) : !stepState.hasFilledContent ? (
        <LearnHowToGenerateContentContainer
          stepState={stepState}
          onGoBack={() =>
            dispatchSteps({
              type: OnboardingActionType.CHOOSE_CONTENT_SOURCE,
              contentSource: undefined,
            })
          }
          onChooseStyle={() => {
            setIsSubmitted(true);
            if (
              validateForm(
                contentUrl,
                postSubject,
                profileUrl,
                setContentUrlError,
                setPostSubjectError,
                setProfileUrlError,
                setPostSubjectUrlError,
                stepState,
              )
            ) {
              return;
            }
            resetErrors(
              setContentUrlError,
              setPostSubjectError,
              setProfileUrlError,
              setPostSubjectUrlError,
              setIsSubmitted,
            );
            trackEvent('Onboarding - Click - Input content source');
            dispatchSteps({
              type: OnboardingActionType.FILL_IN_CONTENT,
            });
          }}>
          {stepState.contentSource === ContentSource.FROM_AN_URL ? (
            <GenerateFromContentURL
              onGoBack={() =>
                dispatchSteps({
                  type: OnboardingActionType.CHOOSE_CONTENT_SOURCE,
                  contentSource: undefined,
                })
              }
              {...{
                contentUrl,
                setContentUrl,
                postSubject,
                setPostSubject,
                isSubmitted,
                contentUrlError,
                postSubjectUrlError,
                isOnboarding: true,
              }}
            />
          ) : stepState.contentSource === ContentSource.MY_OWN_IDEAS ? (
            <GenerateFromMyOwnIdeas
              onGoBack={() =>
                dispatchSteps({
                  type: OnboardingActionType.CHOOSE_CONTENT_SOURCE,
                  contentSource: undefined,
                })
              }
              {...{
                setPostSubject,
                setPostIdeas,
                postIdeas,
                postSubject,
                postSubjectError,
                isSubmitted,
                isOnboarding: true,
              }}
            />
          ) : stepState.contentSource === ContentSource.I_DONT_HAVE_IDEAS ? (
            <GenerateFromNoIdeas
              onGoBack={() =>
                dispatchSteps({
                  type: OnboardingActionType.CHOOSE_CONTENT_SOURCE,
                  contentSource: undefined,
                })
              }
              onChoosenIdea={(subject, ideas) => {
                trackEvent('Onboarding - Click - Chose idea');
                setPostSubject(subject);
                setPostIdeas(ideas);
                dispatchSteps({
                  type: OnboardingActionType.CHOOSE_CONTENT_SOURCE,
                  contentSource: ContentSource.MY_OWN_IDEAS,
                });
              }}
              {...{postLang, setPostLang}}
            />
          ) : null}
        </LearnHowToGenerateContentContainer>
      ) : !stepState.writingStyle ? (
        <ChooseStyleSource
          setPostTone={setPostTone}
          chooseStyleSource={(writingStyle) => {
            setSelectedStyleSource(writingStyle);
            trackEvent('Onboarding - Click - Chose style source', {
              writingStyle: writingStyle,
            });
            dispatchSteps({
              type: OnboardingActionType.CHOOSE_WRITING_STYLE,
              writingStyle,
            });
          }}
          onGoBack={() => {
            dispatchSteps({
              type: OnboardingActionType.FILL_IN_CONTENT_BACK,
            });
          }}
        />
      ) : !stepState.finalStep ? (
        <LearnHowToGenerateContentContainer
          stepState={stepState}
          onGoBack={() => {
            dispatchSteps({
              type: OnboardingActionType.CHOOSE_WRITING_STYLE,
              writingStyle: undefined,
            });
          }}
          onWritePost={() => {
            setIsSubmitted(true);
            if (
              validateForm(
                contentUrl,
                postSubject,
                profileUrl,
                setContentUrlError,
                setPostSubjectError,
                setProfileUrlError,
                setPostSubjectUrlError,
                stepState,
              )
            ) {
              return;
            }
            resetErrors(
              setContentUrlError,
              setPostSubjectError,
              setProfileUrlError,
              setPostSubjectUrlError,
              setIsSubmitted,
            );
            onGeneratePostSubmit();
            dispatchSteps({
              type: OnboardingActionType.FINAL_STEP,
            });
          }}>
          {stepState.contentSource === ContentSource.FROM_AN_URL ? (
            <GenerateFromContentURL
              onGoBack={() =>
                dispatchSteps({
                  type: OnboardingActionType.CHOOSE_CONTENT_SOURCE,
                  contentSource: undefined,
                })
              }
              {...{
                contentUrl,
                setContentUrl,
                postSubject,
                setPostSubject,
                postSubjectUrlError,
                contentUrlError,
                isSubmitted,
                isOnboarding: true,
              }}
            />
          ) : stepState.contentSource === ContentSource.MY_OWN_IDEAS ? (
            <GenerateFromMyOwnIdeas
              onGoBack={() =>
                dispatchSteps({
                  type: OnboardingActionType.CHOOSE_CONTENT_SOURCE,
                  contentSource: undefined,
                })
              }
              {...{
                setPostSubject,
                setPostIdeas,
                postIdeas,
                postSubject,
                postSubjectError,
                isSubmitted,
                isOnboarding: true,
              }}
            />
          ) : stepState.contentSource === ContentSource.I_DONT_HAVE_IDEAS ? (
            <GenerateFromNoIdeas
              onGoBack={() =>
                dispatchSteps({
                  type: OnboardingActionType.CHOOSE_CONTENT_SOURCE,
                  contentSource: undefined,
                })
              }
              {...{postLang, setPostLang}}
            />
          ) : null}
          {stepState.writingStyle === StyleSource.PROFILE_URL ||
          stepState.writingStyle === StyleSource.OTHERS_PROFILE_URL ? (
            <ProfileUrlStyle
              onGoBack={() =>
                dispatchSteps({
                  type: OnboardingActionType.CHOOSE_WRITING_STYLE,
                })
              }
              {...{
                setProfileUrl,
                profileUrl,
                setPostLang,
                postLang,
                profileUrlError,
                isSubmitted,
                isOnboarding: true,
              }}
            />
          ) : (
            <PostStyle
              onGoBack={() => {
                dispatchSteps({
                  type: OnboardingActionType.CHOOSE_WRITING_STYLE,
                });
              }}
              chooseStyleSource={(writingStyle) => {
                dispatchSteps({
                  type: OnboardingActionType.CHOOSE_WRITING_STYLE,
                  writingStyle,
                });
              }}
              {...{
                setPostTone,
                postTone,
                postLang,
                setPostLang,
                isOnboarding: true,
              }}
            />
          )}
        </LearnHowToGenerateContentContainer>
      ) : (
        <PostGeneration {...{posts, stepState, isLoading}} />
      )}
    </Container>
  );
};

const Container = styled.div`
  background-image: url(${BackgroundBlur});
  min-height: calc(100vh - 3em);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 1.5em;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4em;
`;
const SubContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
`;
const Welcome = styled.span`
  font-size: 2.75em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  text-align: center;
`;
const StrongText = styled.span`
  font-weight: 700;
`;
const Question = styled.span`
  font-size: 1.25em;
  color: ${({theme}) => theme.colors.neutral.shade10};
  letter-spacing: 0.0125em;
  text-align: center;
`;
const StyledRedactaiIcon = styled(RedactaiIcon)`
  @media (max-width: ${isTablet}) {
    scale: 0.75;
  }
`;
