import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Chip} from '../atoms/Chip';
import {Button} from '../atoms/Button';
import LikeIcon from '../atoms/Icons/LikeIcon';
import DislikeIcon from '../atoms/Icons/DislikeIcon';
import CopyIcon from '../atoms/Icons/CopyIcon';
import {t} from '@lingui/macro';
import {getInitials} from '../../utils/getInitials';
import {SavedPostType} from '../../hooks/savedPost/savedPost.types';
import {format} from 'date-fns';
import {formatRelativeTime} from '../../utils/formatRelativeTime';
import {useSnackbar} from 'notistack';
import {useLikeSavedPost} from '../../hooks/savedPostsAndIdeas/useLikeSavedPost';
import {useMarkSavedPostAsUsed} from '../../hooks/savedPostsAndIdeas/useMarkSavedPostAsUsed';
import {useTheme} from '../theme/theme';
import {isTablet} from '../../utils/responsive';
import {PreviewPopup} from './popups/PreviewPopup';
import {TwoDoneIcon} from '../atoms/Icons/TwoDoneIcon';
import {CloseIcon} from '../atoms/Icons/CloseIcon';
import EditIcon from '../atoms/Icons/EditIcon';

interface Props {
  post: SavedPostType;
  height?: string;
  width?: string;
}

export const SavedPost: FunctionComponent<Props> = ({post, height, width}) => {
  const theme = useTheme();
  const {enqueueSnackbar} = useSnackbar();
  const likeSavedPost = useLikeSavedPost();
  const [showpreviewPopup, setShowPreviewPopup] = useState(false);
  const [postText, setPostText] = useState(post.postContent);
  const markSavedPostAsUsed = useMarkSavedPostAsUsed();
  const likePost = useCallback(
    (like: boolean) => {
      likeSavedPost.mutate({id: post.id, like});
    },
    [likeSavedPost, post.id],
  );

  useEffect(() => {
    setPostText(post.postContent);
  }, [post.postContent]);
  return (
    <PostContainer height={height} width={width}>
      <StyledChip
        label={t`Created at ${format(post.createdAt, 'MM/dd/yyyy, hh:mm:ss')}`}
        variant="date"
      />
      <Header>
        <AvatarWrapper>
          <Avatar>{getInitials(post.authorName)}</Avatar>
        </AvatarWrapper>
        <UserInfo>
          <UserName>{post.authorName}</UserName>
          <UserSubtitle>{t`I use RedactAI because I'm smart`}</UserSubtitle>
          <TimeAgo>
            {formatRelativeTime(post.createdAt)}
            <Dot />
          </TimeAgo>
        </UserInfo>
      </Header>
      <Content>{postText}</Content>
      <div>
        <FooterActions>
          <SocialActions>
            <LikeIcon
              onClick={() => likePost(true)}
              color={`${post.isLiked === true ? theme.colors.primary1.shade2 : theme.colors.neutral.shade11}`}
            />
            <DislikeIcon
              onClick={() => likePost(false)}
              color={`${post.isLiked === false ? theme.colors.primary1.shade2 : theme.colors.neutral.shade11}`}
            />
            <CopyIcon
              onClick={async () => {
                await navigator.clipboard.writeText(postText);
                enqueueSnackbar(t`Copied to clipboard`, {
                  variant: 'success',
                });
              }}
            />
            <StyledEditIcon
              onClick={() => setShowPreviewPopup(true)}
              color={theme.colors.neutral.shade11}
              height={19.5}
              width={19.5}
            />
          </SocialActions>
          {!post.isUsed ? (
            <MarkAsUsedText onClick={() => markSavedPostAsUsed.mutate(post.id)}>
              <TwoDoneIcon />
              {t`Mark as used`}
            </MarkAsUsedText>
          ) : (
            <MarkAsNotUsedText
              onClick={() => markSavedPostAsUsed.mutate(post.id)}>
              <CloseIcon color={theme.colors.primary2.shade3} size={19} />
              {t`Mark as not used`}
            </MarkAsNotUsedText>
          )}
        </FooterActions>
        <StyledButton
          label={t`See preview`}
          size="medium"
          variant="main"
          onClick={() => setShowPreviewPopup(true)}
        />
      </div>
      {showpreviewPopup && (
        <PreviewPopup
          setShowPreviewPopup={setShowPreviewPopup}
          name={post.authorName}
          initialText={postText}
          postId={post.id}
          onTextChange={setPostText}
          createdAt={post.createdAt}
          invalidateQueries
        />
      )}
    </PostContainer>
  );
};

const TimeAgo = styled.span`
  font-size: 0.8em;
  opacity: 60%;
  display: flex;
  align-items: center;
`;
const Dot = styled.span`
  width: 0.3em;
  height: 0.3em;
  background-color: ${({theme}) => theme.colors.neutral.shade11};
  border-radius: 50%;
  margin-left: 0.5em;
`;
const PostContainer = styled.div<{
  height: Props['height'];
  width: Props['width'];
}>`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  height: ${({height}) => height};
  width: ${({width}) => width};
  border-radius: 1.5em;
  border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  @media (max-width: ${isTablet}) {
    width: 20em;
  }
`;
const Header = styled.div`
  display: flex;
  gap: 0.9375em;
`;
const AvatarWrapper = styled.div`
  height: 3.6em;
  width: 3.6em;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.neutral.shade5};
  margin-top: 0.15em;
`;
const Avatar = styled.p`
  font-size: 1.25em;
  font-weight: 700;
`;
const UserInfo = styled.div`
  color: #000000;
  font-size: 1em;
`;
const UserName = styled.p`
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.0125em;
  margin: 0;
`;
const UserSubtitle = styled.p`
  font-size: 0.8125em;
  letter-spacing: 0.00625em;
  opacity: 60%;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
`;
const Content = styled.div`
  line-height: 1.5em;
  letter-spacing: 0.03125em;
  color: ${({theme}) => theme.colors.neutral.shade12};
  flex: 1;
  overflow-y: scroll;
  white-space: pre-wrap;
  word-wrap: break-word;
`;
const FooterActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  margin-bottom: 1em;
`;
const SocialActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  & > * {
    cursor: pointer;
  }
`;
const StyledChip = styled(Chip)`
  width: fit-content;
`;
const StyledButton = styled(Button)`
  width: 100%;
`;
const MarkAsUsedText = styled.div`
  color: ${({theme}) => theme.colors.primary4.shade1};
  font-weight: 600;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 0.375em;
  cursor: pointer;
`;
const MarkAsNotUsedText = styled.div`
  color: ${({theme}) => theme.colors.primary2.shade3};
  font-weight: 600;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 0.1em;
  cursor: pointer;
`;
const StyledEditIcon = styled(EditIcon)`
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;
