import {FunctionComponent, useContext, useMemo} from 'react';
import styled from 'styled-components';
import {Button} from '../atoms/Button';
import ArrowRight from '../atoms/Icons/ArrowRightIcon';
import PostsLeftIcon from '../atoms/Icons/PostsLeftIcon';
import {ProgressBar} from '../atoms/ProgressBar';
import {Status} from '../atoms/Status';
import {format} from 'date-fns';
import {t} from '@lingui/macro';
import {getInitials} from '../../utils/getInitials';
import {UserContext} from '../../common/UserContext/user.context';
import {useNavigate} from 'react-router-dom';

interface Props {
  name: string;
  numberOfPostsLeft: number;
  numberOfPosts: number;
  renewalDate: Date;
  canceled: boolean;
  cancelsAtPeriodEnd?: boolean;
  validUntil?: Date | null;
  className?: string;
  isMobile?: boolean;
}

enum SubscriptionStatus {
  FREE = 'FREE',
  FREE_WITHOUT_POSTS_LEFT = 'FREE_WITHOUT_POSTS_LEFT',
  OLD_SUBSCRIPTION = 'OLD_SUBSCRIPTION',
  OLD_SUBSCRIPTION_WITHOUT_POSTS_LEFT = 'OLD_SUBSCRIPTION_WITHOUT_POSTS_LEFT',
  OLD_SUBSCRIPTION_CANCELED = 'OLD_SUBSCRIPTION_CANCELED',
  CANCELED = 'CANCELED',
  SUBSCRIBED = 'SUBSCRIBED',
}

export const UNLIMITED_SUBSCRIPTION_NUMBER_OF_POSTS = 1000;

const getSubscriptionStatus = (
  numberOfPostsLeft: number,
  numberOfPosts: number,
  isClient: boolean,
  canceled: boolean,
): SubscriptionStatus => {
  if (!isClient && numberOfPostsLeft === 0) {
    return SubscriptionStatus.FREE_WITHOUT_POSTS_LEFT;
  }
  if (!isClient) {
    return SubscriptionStatus.FREE;
  }
  if (canceled && numberOfPosts === UNLIMITED_SUBSCRIPTION_NUMBER_OF_POSTS) {
    return SubscriptionStatus.CANCELED;
  }
  if (canceled) {
    return SubscriptionStatus.OLD_SUBSCRIPTION_CANCELED;
  }
  if (numberOfPosts === UNLIMITED_SUBSCRIPTION_NUMBER_OF_POSTS) {
    return SubscriptionStatus.SUBSCRIBED;
  }
  if (numberOfPostsLeft === 0) {
    return SubscriptionStatus.OLD_SUBSCRIPTION_WITHOUT_POSTS_LEFT;
  }
  return SubscriptionStatus.OLD_SUBSCRIPTION;
};

export const SubscriptionInformationCard: FunctionComponent<Props> = ({
  name,
  numberOfPostsLeft,
  numberOfPosts,
  renewalDate,
  canceled,
  cancelsAtPeriodEnd,
  validUntil,
  className,
  isMobile,
}) => {
  const navigate = useNavigate();
  const {me} = useContext(UserContext);

  const status = getSubscriptionStatus(
    numberOfPostsLeft,
    numberOfPosts,
    !!me?.stripeCustomerId,
    canceled,
  );
  const formattedValidUntil = useMemo(
    () => validUntil && format(validUntil, 'MMMM dd YYYY'),
    [validUntil],
  );

  if (isMobile) {
    switch (status) {
      case SubscriptionStatus.SUBSCRIBED:
        return <Status label={t`Unlimited posts`} variant="completed" />;
      case SubscriptionStatus.OLD_SUBSCRIPTION:
      case SubscriptionStatus.FREE:
        return (
          <PostsLeftMobile>
            <PostsLeftIcon width={15} height={15} color="white" />
            <StrongTextMobile>
              {numberOfPostsLeft}/{numberOfPosts} {t` posts left`}
            </StrongTextMobile>
          </PostsLeftMobile>
        );
      case SubscriptionStatus.FREE_WITHOUT_POSTS_LEFT:
      case SubscriptionStatus.OLD_SUBSCRIPTION_WITHOUT_POSTS_LEFT:
        return <Status label={t`0 Credit left`} variant="warningOutlined" />;
      case SubscriptionStatus.CANCELED:
      case SubscriptionStatus.OLD_SUBSCRIPTION_CANCELED:
        return (
          <Status label={t`Subscription Canceled`} variant="warningOutlined" />
        );
    }
  }

  const PostsLeftContent = () => {
    return (
      <PostsLeft>
        <PostsLeftIcon width={17} height={17} />
        <PostsLeftText>
          <StrongText>
            {numberOfPostsLeft}/{numberOfPosts}{' '}
          </StrongText>
          {t`posts left`}
        </PostsLeftText>
      </PostsLeft>
    );
  };

  const SubscriptionCardContent = () => {
    switch (status) {
      case SubscriptionStatus.SUBSCRIBED:
        return (
          <>
            <PostsLeft>
              <PostsLeftIcon width={17} height={17} />
              <PostsLeftText>
                <StrongText>{t`Unlimited posts`}</StrongText>
              </PostsLeftText>
            </PostsLeft>
            <ProgressBar
              progress={100}
              height="0.6em"
              background="#E7E3DA"
              fullWidth
            />
            {cancelsAtPeriodEnd && (
              <Gap>
                {formattedValidUntil && (
                  <PostsLeftText>
                    {t`Valid until: `}{' '}
                    <StyledDate>{formattedValidUntil}</StyledDate>
                  </PostsLeftText>
                )}
                <Status
                  label={t`Subscription Canceled`}
                  variant="warningOutlined"
                />
              </Gap>
            )}
            <Button
              onClick={() => navigate('/pricing')}
              endIcon={<ArrowRight height={15} width={15} />}
              label={
                cancelsAtPeriodEnd
                  ? t`Renew subscription`
                  : t`Change subscription`
              }
              size="small"
              variant="main"
              fullWidth
            />
          </>
        );
      case SubscriptionStatus.OLD_SUBSCRIPTION:
        return (
          <>
            <PostsLeftContent />
            <ProgressBar
              progress={(numberOfPostsLeft / numberOfPosts) * 100}
              height="0.6em"
              background="#E7E3DA"
              fullWidth
            />
            {cancelsAtPeriodEnd ? (
              <Gap>
                {formattedValidUntil && (
                  <PostsLeftText>
                    {t`Valid until: `}{' '}
                    <StyledDate>{formattedValidUntil}</StyledDate>
                  </PostsLeftText>
                )}
                <Status
                  label={t`Subscription Canceled`}
                  variant="warningOutlined"
                />
              </Gap>
            ) : (
              <PostsLeftText>
                {t`Renewal date: `}{' '}
                <StyledDate>
                  {renewalDate && format(renewalDate, 'MMMM dd')}
                </StyledDate>
              </PostsLeftText>
            )}
            <Button
              onClick={() => navigate('/pricing')}
              endIcon={<ArrowRight height={15} width={15} />}
              label={
                cancelsAtPeriodEnd ? t`Renew subscription` : t`Get more posts`
              }
              size="small"
              variant="main"
              fullWidth
            />
          </>
        );
      case SubscriptionStatus.FREE:
        return (
          <>
            <PostsLeftContent />
            <ProgressBar
              progress={(numberOfPostsLeft / numberOfPosts) * 100}
              height="0.6em"
              background="#E7E3DA"
              fullWidth
            />
            <Button
              onClick={() => navigate('/pricing')}
              endIcon={<ArrowRight height={15} width={15} />}
              label={t`Get unlimited posts`}
              size="small"
              variant="main"
              fullWidth
            />
          </>
        );
      case SubscriptionStatus.FREE_WITHOUT_POSTS_LEFT:
      case SubscriptionStatus.OLD_SUBSCRIPTION_WITHOUT_POSTS_LEFT:
        return (
          <ContentContainer>
            <PostsLeftContent />
            <Status label={t`0 posts left`} variant="warningOutlined" />
            {status ===
              SubscriptionStatus.OLD_SUBSCRIPTION_WITHOUT_POSTS_LEFT && (
              <PostsLeftText>
                {t`Renewal date: `}{' '}
                <StyledDate>
                  {renewalDate && format(renewalDate, 'MMMM dd')}
                </StyledDate>
              </PostsLeftText>
            )}
            <Button
              onClick={() => navigate('/pricing')}
              endIcon={<ArrowRight height={15} width={15} />}
              label={t`Get more posts`}
              size="small"
              variant="main"
              fullWidth
            />
          </ContentContainer>
        );
      case SubscriptionStatus.CANCELED:
      case SubscriptionStatus.OLD_SUBSCRIPTION_CANCELED:
        return (
          <>
            {status === SubscriptionStatus.OLD_SUBSCRIPTION_CANCELED && (
              <PostsLeftContent />
            )}
            <Status
              label={t`Subscription Canceled`}
              variant="warningOutlined"
            />
            <Button
              onClick={() => navigate('/pricing')}
              endIcon={<ArrowRight height={15} width={15} />}
              label={t`Renew subscription`}
              size="small"
              variant="main"
              fullWidth
            />
          </>
        );
    }
  };

  return (
    <CardContainer className={className}>
      <Header>
        <AvatarContainer>
          <Avatar>{getInitials(name)}</Avatar>
        </AvatarContainer>
        <Name>{name}</Name>
      </Header>
      <SubscriptionCardContent />
    </CardContainer>
  );
};

const CardContainer = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade5};
  padding: 1em;
  border-radius: 1.25em;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-top: -7px;
`;
const AvatarContainer = styled.div`
  height: 2.375em;
  width: 2.375em;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.neutral.shade5};
`;
const Avatar = styled.p`
  font-size: 0.9em;
  font-weight: 700;
`;
const Name = styled.p`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.0125em;
  line-height: 1.5em;
`;
const PostsLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
const PostsLeftMobile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  border: 1px solid ${({theme}) => theme.colors.neutral.shade9};
  border-radius: 0.625em;
  padding: 0.2em 0.625em 0.2em 0.3125em;
`;
const PostsLeftText = styled.div`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 0.9em;
  line-height: 1.5em;
  letter-spacing: 0.0125em;
`;

const StrongText = styled.span`
  font-weight: 700;
  font-size: 1.25em;
  line-height: 0.5em;
  letter-spacing: 0.0125em;
`;
const StrongTextMobile = styled.span`
  color: ${({theme}) => theme.colors.neutral.shade1};
  font-weight: 700;
  font-size: 1em;
  letter-spacing: 0.0125em;
`;
const StyledDate = styled.span`
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.0125em;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1em;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;
const Gap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25em;
`;
