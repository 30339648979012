import {FunctionComponent} from 'react';
import styled from 'styled-components';
import PostBoosterScreenshot1 from '../../images/HowTo_PostBooster_1.png';
import PostBoosterScreenshot2 from '../../images/HowTo_PostBooster_2.png';

export const HowToUsePostBooster: FunctionComponent = () => {
  return (
    <TutoContainer>
      <TutoList>
        <Div>
          <Title>Steps to Boost Your LinkedIn Post Using RedactAI</Title>
          <Text>{`It's easy! Start by copying your LinkedIn post text. Then, paste it into the designated field in our Post Booster tool and click the Boost button.`}</Text>
          <StyledImage
            src={PostBoosterScreenshot1}
            alt="Boost Your LinkedIn Post"
          />
        </Div>
        <Div>
          <Title>{`Once you're satisfied with the enhanced post, copy it and use it on LinkedIn. If you'd like to make another attempt, click the Try Again button.`}</Title>
          <StyledImage
            src={PostBoosterScreenshot2}
            alt="Boost Your LinkedIn Post"
          />
        </Div>
        <Div>
          <Title>Tips for Creating a High-Impact LinkedIn Post</Title>
          <StyledList>
            <ListItem>{`Start with a hook. Grab attention with a strong opening statement or question.`}</ListItem>
            <ListItem>{`Focus on value. Clearly state the benefits or value of your post to your audience.`}</ListItem>
            <ListItem>{`Include a call to action. Encourage readers to take the next step, whether it's commenting, sharing, or visiting your profile.`}</ListItem>
            <ListItem>{`Keep it relevant. Make sure your content aligns with your professional brand and interests.`}</ListItem>
            <ListItem>{`Be authentic. Let your personality shine through your post to create a genuine connection.`}</ListItem>
          </StyledList>
          <Title>The Impact of a Well-Crafted LinkedIn Post</Title>
          <StyledList>
            <ListItem>{`Capture attention quickly. A compelling post helps you stand out in a crowded feed.`}</ListItem>
            <ListItem>{`Enhance your professional image. High-quality posts can boost your reputation and credibility.`}</ListItem>
            <ListItem>{`Drive engagement. An engaging post encourages likes, comments, and shares, expanding your reach.`}</ListItem>
            <ListItem>{`Create networking opportunities. A great post can attract the attention of potential connections and collaborators.`}</ListItem>
          </StyledList>
        </Div>
      </TutoList>
    </TutoContainer>
  );
};

const TutoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60em;
  margin: 0 auto;
  margin-top: 3em;
`;

const TutoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  justify-content: center;
  text-align: center;
`;

const Title = styled.span`
  font-size: 1.35em;
  font-weight: 700;
`;

const Text = styled.span`
  font-size: 1.15em;
  line-height: 1.5em;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const StyledImage = styled.img`
  height: 60%;
  width: 60%;
  object-fit: contain;
  align-self: center;
`;

const StyledList = styled.ul`
  list-style-type: disc;
  padding-left: 1.5em;
  text-align: left;
  margin: 0.5em 0;
`;

const ListItem = styled.li`
  font-size: 1.1em;
  line-height: 1.4em;
  margin-bottom: 0.5em;
`;
