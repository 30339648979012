export interface Tool {
  name: string;
  description: string;
  link: string;
}

export const tools: Tool[] = [
  {
    name: 'LinkedIn Post Formatter',
    description:
      'Enhance the visual appeal of your LinkedIn posts with ease, making them stand out in the feed.',
    link: '/free-tools/text-formatter',
  },
  {
    name: 'LinkedIn Headline Generator',
    description:
      'Craft impactful LinkedIn headlines that capture attention and elevate your profile visibility.',
    link: '/free-tools/headline-generator',
  },
  {
    name: 'LinkedIn Video Downloader',
    description:
      'Effortlessly download LinkedIn videos to repurpose and share them across different platforms.',
    link: '/free-tools/video-downloader',
  },
  {
    name: 'LinkedIn Summary Generator',
    description:
      'Leverage AI to refine your LinkedIn summary, turning profile visitors into valuable business opportunities.',
    link: '/free-tools/summary-generator',
  },
  {
    name: 'LinkedIn Profile Feedback',
    description:
      'Get free, AI-powered suggestions to improve your LinkedIn profile. Boost your visibility, attract more opportunities, and stand out with tailored optimization tips designed to help you succeed on LinkedIn!',
    link: '/free-tools/profile-feedback',
  },
  {
    name: 'LinkedIn Post Booster',
    description:
      "Improve your LinkedIn posts with RedactAi's advanced AI. Transform your content into captivating posts that drive engagement and go viral.",
    link: '/free-tools/post-booster',
  },
];

export const blogs: Tool[] = [
  {
    name: 'How the LinkedIn Algorithm Works in 2024',
    description:
      'Unraveling the LinkedIn algorithm for 2024. Gain expert insights and strategies for maximizing visibility and engagement.🚀',
    link: 'https://www.redactai.io/blog/how-the-linkedin-algorithm-works-in-2024',
  },
  {
    name: 'How to Use Hashtags for Linkedin in 2024',
    description:
      "Hashtags that work on other sites may not apply on LinkedIn. Here's how to build a LinkedIn hashtag strategy that will help you grow.🚀",
    link: 'https://www.redactai.io/blog/how-to-use-hashtags-for-linkedin-in-2024',
  },
  {
    name: 'Best Times to Post on LinkedIn in 2024',
    description:
      'Discover the optimal times to post on LinkedIn in 2024 with RedactAI’s expert insights. Boost your engagement and elevate your social media strategy today! 🚀',
    link: 'https://www.redactai.io/blog/best-times-to-post-on-linkedin-in-2024',
  },
  {
    name: 'How to Promote Yourself on LinkedIn: Proven Techniques for Self-Promotion',
    description:
      "Master Self-Promotion on LinkedIn: Understand and Meet Your Audience's Needs. Our Expert Tips Make It Easy.",
    link: 'https://www.redactai.io/blog/how-to-promote-yourself-on-linkedin-proven-techniques-for-self-promotion',
  },
  {
    name: 'How to Select LinkedIn Image Sizes in 2024',
    description:
      'Keep your LinkedIn profile engaging with optimal image sizes for 2024. Stay updated with size recommendations to ensure your visual content impacts positively.',
    link: 'https://www.redactai.io/blog/how-to-select-linkedin-image-sizes-in-2024',
  },
  {
    name: 'What to Post on LinkedIn in 2024 | LinkedIn Content Ideas 👨‍💻',
    description:
      'If you’re not sure of what to post on LinkedIn, discover the most effective LinkedIn content ideas you start out with in 2024. Increase your LinkedIn effectiveness today !',
    link: 'https://www.redactai.io/blog/what-to-post-on-linkedin-in-2024',
  },
];
