import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {Button} from '../../../components/atoms/Button';
import RedactAiScreenshot from '../images/RedactAi_Screenshot.png';
import {isTablet} from '../../../utils/responsive';

export const DiscoverSection: FunctionComponent = () => {
  return (
    <Section>
      <Content>
        <TextContainer>
          <Title>
            Unlock the AI-driven LinkedIn toolkit designed to elevate your
            personal brand.
          </Title>
          <StyledButton
            onClick={() => window.open('https://www.redactai.io/')}
            variant={'main'}
            size="big"
            label={'Try RedactAI'}
          />
        </TextContainer>
        <ImageContainer>
          <ScreenshotImage src={RedactAiScreenshot} alt="Website Screenshot" />
        </ImageContainer>
      </Content>
    </Section>
  );
};

const Section = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    20deg,
    ${({theme}) => theme.colors.primary1.shade3} 10%,
    ${({theme}) => theme.colors.primary1.shade2} 90%
  );
  padding: 2em;
  height: 30em;
  margin: 2em -1.5em;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  @media (max-width: ${isTablet}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40%;

  @media (max-width: ${isTablet}) {
    max-width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.h2`
  font-size: 3em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade1};
  text-shadow: 0 0 0.1em ${({theme}) => theme.colors.primary1.shade1};
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 55%;
  @media (max-width: ${isTablet}) {
    display: none;
  }
`;

const ScreenshotImage = styled.img`
  max-width: 100%;
  max-height: 30em;
  height: auto;
  border-radius: 0.5em;
`;

const StyledButton = styled(Button)`
  max-width: 40%;

  @media (max-width: ${isTablet}) {
    max-width: 100%;
  }
`;
